import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbTemplateInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-template-input',
  templateUrl: './cb-template-input.component.html',
  styleUrls: ['./cb-template-input.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbTemplateInputComponent implements ControlValueAccessor {
  @Input() public placeholder?: string = '';
  @Input() public label?: string = '';
  @Input() public autofocus?: boolean;
  @Input() public autocomplete?: string;
  @Input() public lowercase?: boolean;
  @Input() public disabled = false;

  @Input() public rows?: number = 1;

  @Input() public template: string[] = [];

  @Output('input') public onInput: EventEmitter<string> =
    new EventEmitter<string>();
  @Output('change') public onChange: EventEmitter<string> =
    new EventEmitter<string>();

  @ViewChild('input') private inputRef?: ElementRef;

  //The internal data model
  private innerValue = '';

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.autofocus) this.focusInput();
  }

  ngAfterViewInit(): void {}

  public addTemplateString(event: MouseEvent, template: string): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    const selectionStart: number =
      this.inputRef?.nativeElement.selectionStart || 0;
    let value = `{{${template.toLowerCase().replace(' ', '_')}}}`;

    if (this.value) {
      if (selectionStart > 0 && this.value[selectionStart - 1] !== ' ')
        value = ' ' + value;

      if (
        selectionStart < this.value.length &&
        this.value[selectionStart] !== ' '
      )
        value += ' ';

      this.value =
        this.value.substring(0, selectionStart) +
        value +
        this.value.substring(selectionStart);
    } else {
      this.value = value;
    }
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      if (this.lowercase && v) v = v.toLowerCase();

      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: string) {
    if (value !== this.innerValue) {
      if (this.lowercase && value) value = value.toLowerCase();

      this.innerValue = value;
      this.onChangeCallback(value);
      this.cdr.detectChanges();
    }
  }

  public input(): void {
    this.onInput.emit(this.value);
  }
  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public focusInput(): void {
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }
}
