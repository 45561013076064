import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: string, maxChars: number = 2): string {
    const split: string[] = value.split(' ');
    let res: string = '';

    for (let i = 0; i < Math.min(split.length, maxChars); i++) {
      res += split[i].charAt(0).toUpperCase();
    }

    return res;
  }
}
