<div class="cb-input-wrapper" [class.disabled]="disabled">
  <label>{{label}}</label>
  <div class="cb-input-template-buttons">
    <ng-container *ngFor="let value of template">
      <ng-container *ngIf="value">
        <button class="cb-input-template-button" (mousedown)="addTemplateString($event, value)">{{value}}</button>
      </ng-container>
    </ng-container>
  </div>
  <div class="cb-input-container">
    <div class="cb-input-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="cb-input">
      <ng-container *ngIf="rows==1; else TextareaTemplate">
        <input #input [autocomplete]="autocomplete" [placeholder]="placeholder" [(ngModel)]="value" type="text"
          (change)="change()" />
      </ng-container>
      <ng-template #TextareaTemplate>
        <textarea #input [autocomplete]="autocomplete" [placeholder]="placeholder" [(ngModel)]="value" type="text"
          (change)="change()" [rows]="rows" autosize></textarea>
      </ng-template>
    </div>
    <div class="cb-input-suffix">
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>
</div>
