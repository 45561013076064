import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsTime',
})
export class SecondsTimePipe implements PipeTransform {
    constructor() { }

    transform(value?: number): string {
        let str: string = '';
        if (value) {
            const hours: number = Math.floor(value / 3600);
            const minutes: number = Math.floor((value - hours * 3600) / 60);
            const seconds: number = value - hours * 3600 - minutes * 60;

            str = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        }

        return str;
    }
}
