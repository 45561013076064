import { formatCurrency } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "currency",
})
export class CurrencyPipe implements PipeTransform {
    constructor() { }

    public transform(
        value?: number,
        currencyCode: string = "EUR",
        display: "code" | "symbol" | "symbol-narrow" | string | boolean = "symbol",
        digitsInfo: string = "",
        locale: string = "en-US",
        currencySymbol: string = "€"
    ): string | null {
        if (typeof value != "number")
            return "";

        return formatCurrency(
            value,
            locale,
            currencySymbol,
            currencyCode,
            digitsInfo
        );
    }
}