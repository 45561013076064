import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EMOJI_COMPONENTS, EMOJI_MAP } from './emojis';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbIconsPickerComponent),
  multi: true,
};

export interface IconColorPick {
  icon: string;
  color: string;
}

@Component({
  selector: 'cb-icons-picker',
  templateUrl: './cb-icons-picker.component.html',
  styleUrls: ['./cb-icons-picker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbIconsPickerComponent implements ControlValueAccessor, OnInit {

  public readonly emojiSkinTones = [
    { placeholder: "🤚", value: "" },
    { placeholder: "🤚🏻", value: EMOJI_COMPONENTS.light_skin_tone },
    { placeholder: "🤚🏼", value: EMOJI_COMPONENTS.medium_light_skin_tone },
    { placeholder: "🤚🏽", value: EMOJI_COMPONENTS.medium_skin_tone },
    { placeholder: "🤚🏾", value: EMOJI_COMPONENTS.medium_dark_skin_tone },
    { placeholder: "🤚🏿", value: EMOJI_COMPONENTS.dark_skin_tone },
  ];
  public readonly colorsList =
    [
      { color: "#222222", contrast: "#ffffff" },
      { color: "#192a56", contrast: "#ffffff" },
      { color: "#7158e2", contrast: "#ffffff" },
      { color: "#9c88ff", contrast: "#ffffff" },
      { color: "#54a0ff", contrast: "#ffffff" },
      { color: "#0abde3", contrast: "#ffffff" },
      { color: "#00d2d3", contrast: "#ffffff" },
      { color: "#1dd1a1", contrast: "#ffffff" },
      { color: "#feca57", contrast: "#ffffff" },
      { color: "#fd9644", contrast: "#ffffff" },
      { color: "#ee5253", contrast: "#ffffff" },
      { color: "#f368e0", contrast: "#ffffff" }
    ];

  public readonly emojiMap = EMOJI_MAP;

  @Output("onChange") public onChange: EventEmitter<IconColorPick> = new EventEmitter<IconColorPick>();

  private innerValue: IconColorPick = {
    icon: "👍",
    color: "#000000",
  };

  private _skinTone: string = EMOJI_COMPONENTS.light_skin_tone;

  public search: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public get emojiRows(): string[][] {
    const list = Object.keys(EMOJI_MAP);
    const rows = [];

    for (let i = 0; i < list.length; i += 8) {
      rows.push(list.slice(i, i + 8));
    }

    return rows;
  }

  // get accessor
  public get icon(): string {
    return this.innerValue.icon;
  }

  // Set accessor including call the onchange callback
  public set icon(v: string) {
    if (v !== this.innerValue.icon) {
      this.innerValue.icon = v;
      this.onChangeCallback(this.innerValue);
    }
  }

  // get accessor
  public get color(): string {
    return this.innerValue?.color ?? '';
  }

  // Set accessor including call the onchange callback
  public set color(v: string) {
    if (v !== this.innerValue.color) {
      this.innerValue.color = v;
      this.onChangeCallback(this.innerValue);
    }
  }


  // From ControlValueAccessor interface
  public writeValue(value: IconColorPick) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public setEmoji(emoji: string) {
    this.icon = emoji;
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
