import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbCodeInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-code-input',
  templateUrl: './cb-code-input.component.html',
  styleUrls: ['./cb-code-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbCodeInputComponent implements ControlValueAccessor, OnInit {

  @Input() public length: number = 6;

  public code: (string | null)[] = [];
  private innerValue: any;

  constructor() { }

  ngOnInit(): void {
    this.code = Array(this.length).fill(null);
  }

  private focusInput(idx: number): void {
    if (idx >= 0 && idx <= this.code.length - 1) {
      setTimeout(() => {
        document.getElementById("code-input-" + idx)?.focus();
      }, 0);
    }
  }

  public checkCode(event: KeyboardEvent, idx: number): void {
    event.preventDefault();

    if (event.key >= "0" && event.key <= "9") {
      this.code[idx] = event.key;

      // Focus the next input
      this.focusInput(idx + 1);
    } else if (event.key == "Delete" || event.key == "Backspace") {
      this.code[idx] = null;

      // Focus the previous input
      this.focusInput(idx - 1);
    } else if (
      event.key == "ArrowRight" ||
      event.key == "Enter" ||
      event.key == "Tab"
    ) {
      // Focus the next input
      this.focusInput(idx + 1);
    } else if (event.key == "ArrowLeft") {
      // Focus the previous input
      this.focusInput(idx - 1);
    }

    this.value = this.code.join("");
  }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.code.join("");
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
