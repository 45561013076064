import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'smartDate',
})
export class SmartDateTimePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  transform(value?: Date, format?: string): string {
    let str: string = '';

    moment.locale("it");

    if (value) {
      let dateMoment: moment.Moment = moment(value);

      if (format) {
        str = dateMoment.format(format);
      } else {
        const today: Date = new Date();

        str = dateMoment.calendar(null, {
          lastDay: `[${this.translateService.instant('COMMON.YESTERDAY')}]`,
          sameDay: `[${this.translateService.instant('COMMON.TODAY')}]`,
          nextDay: `[${this.translateService.instant('COMMON.TOMORROW')}]`,
          nextWeek: 'dddd',
          lastWeek(m: any, now: any) {
            if (value.getFullYear() === today.getFullYear()) {
              return 'DD MMM';
            } else {
              return 'DD MMM YYYY';
            }
          },
          sameElse(m: any, now: any) {
            if (value.getFullYear() === today.getFullYear()) {
              return 'DD MMM';
            } else {
              return 'DD MMM YYYY';
            }
          },
        });
      }
    }

    // Capitalize each word of the string
    str = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); });

    return str;
  }
}
