import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CbDialogService } from '../../cb-dialog/cb-dialog-service/cb-dialog.service';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbTranslateInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-translate-input',
  templateUrl: './cb-translate-input.component.html',
  styleUrls: ['./cb-translate-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbTranslateInputComponent implements ControlValueAccessor, OnInit {
  @Input() public autotranslate = true;
  @Input() public fromLang!: string;
  @Input() public toLang!: string;
  @Input() public fromText = '';

  @Output('change') public onChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output('blur') public onBlur: EventEmitter<string> =
    new EventEmitter<string>();

  public readonly langsMap: { [key: string]: string } = {
    it: 'Italiano',
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
  };

  private readonly googleApiKey: string =
    'AIzaSyBDhg64KH3KczO1_faAnV1xtlC6Z660bPs';

  //The internal data model
  private innerValue = '';

  constructor(
    private readonly dialogService: CbDialogService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public get fromLangExtended(): string {
    return this.langsMap[this.fromLang] ?? '';
  }
  public get toLangExtended(): string {
    return this.langsMap[this.toLang] ?? '';
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public blur(): void {
    this.onBlur.emit(this.value);
  }
  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public autoTranslate(): void {
    const toTranslate: string = this.fromText;

    let canAutoTranslate = true;
    if (this.value && this.value.length > 0) {
      this.dialogService.openConfirmDialog(
        this.translate.instant('TRANSLATE.CONFIRM_AUTO_TRANSLATE_TITLE'),
        this.translate.instant('TRANSLATE.CONFIRM_AUTO_TRANSLATE_MESSAGE'),
        {
          afterClose: (result: boolean) => {
            canAutoTranslate = result;
          },
        }
      );
    }

    if (canAutoTranslate) {
      // ...
    }
  }
}
