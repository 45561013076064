<div class="cb-input-wrapper">
  <label>{{label}}</label>
  <div class="cb-input-container">
    <div class="cb-color-preview" [style.background]="'#'+value"></div>

    <div class="cb-input">
      <span>#</span>
      <input placeholder="ABCDEF" [(ngModel)]="value" type="text" (change)="change()" />
    </div>

  </div>
</div>
