export const EMOJI_COMPONENTS = {
    "light_skin_tone": "🏻",
    "medium_light_skin_tone": "🏼",
    "medium_skin_tone": "🏽",
    "medium_dark_skin_tone": "🏾",
    "dark_skin_tone": "🏿",
    "red_hair": "🦰",
    "curly_hair": "🦱",
    "white_hair": "🦳",
    "bald": "🦲"
};

export const EMOJI_MAP: { [key: string]: { slug: string, group: string, skin: boolean } } = {
    "😀": {
        "slug": "grinning_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😃": {
        "slug": "grinning_face_with_big_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😄": {
        "slug": "grinning_face_with_smiling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😁": {
        "slug": "beaming_face_with_smiling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😆": {
        "slug": "grinning_squinting_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😅": {
        "slug": "grinning_face_with_sweat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤣": {
        "slug": "rolling_on_the_floor_laughing",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😂": {
        "slug": "face_with_tears_of_joy",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙂": {
        "slug": "slightly_smiling_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙃": {
        "slug": "upside_down_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫠": {
        "slug": "melting_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😉": {
        "slug": "winking_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😊": {
        "slug": "smiling_face_with_smiling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😇": {
        "slug": "smiling_face_with_halo",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥰": {
        "slug": "smiling_face_with_hearts",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😍": {
        "slug": "smiling_face_with_heart_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤩": {
        "slug": "star_struck",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😘": {
        "slug": "face_blowing_a_kiss",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😗": {
        "slug": "kissing_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😚": {
        "slug": "kissing_face_with_closed_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😙": {
        "slug": "kissing_face_with_smiling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥲": {
        "slug": "smiling_face_with_tear",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😋": {
        "slug": "face_savoring_food",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😛": {
        "slug": "face_with_tongue",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😜": {
        "slug": "winking_face_with_tongue",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤪": {
        "slug": "zany_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😝": {
        "slug": "squinting_face_with_tongue",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤑": {
        "slug": "money_mouth_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤗": {
        "slug": "smiling_face_with_open_hands",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤭": {
        "slug": "face_with_hand_over_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫢": {
        "slug": "face_with_open_eyes_and_hand_over_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫣": {
        "slug": "face_with_peeking_eye",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤫": {
        "slug": "shushing_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤔": {
        "slug": "thinking_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫡": {
        "slug": "saluting_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤐": {
        "slug": "zipper_mouth_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤨": {
        "slug": "face_with_raised_eyebrow",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😐": {
        "slug": "neutral_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😑": {
        "slug": "expressionless_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😶": {
        "slug": "face_without_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫥": {
        "slug": "dotted_line_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😶‍🌫️": {
        "slug": "face_in_clouds",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😏": {
        "slug": "smirking_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😒": {
        "slug": "unamused_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙄": {
        "slug": "face_with_rolling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😬": {
        "slug": "grimacing_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😮‍💨": {
        "slug": "face_exhaling",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤥": {
        "slug": "lying_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫨": {
        "slug": "shaking_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😌": {
        "slug": "relieved_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😔": {
        "slug": "pensive_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😪": {
        "slug": "sleepy_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤤": {
        "slug": "drooling_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😴": {
        "slug": "sleeping_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😷": {
        "slug": "face_with_medical_mask",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤒": {
        "slug": "face_with_thermometer",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤕": {
        "slug": "face_with_head_bandage",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤢": {
        "slug": "nauseated_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤮": {
        "slug": "face_vomiting",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤧": {
        "slug": "sneezing_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥵": {
        "slug": "hot_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥶": {
        "slug": "cold_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥴": {
        "slug": "woozy_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😵": {
        "slug": "face_with_crossed_out_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😵‍💫": {
        "slug": "face_with_spiral_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤯": {
        "slug": "exploding_head",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤠": {
        "slug": "cowboy_hat_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥳": {
        "slug": "partying_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥸": {
        "slug": "disguised_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😎": {
        "slug": "smiling_face_with_sunglasses",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤓": {
        "slug": "nerd_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🧐": {
        "slug": "face_with_monocle",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😕": {
        "slug": "confused_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🫤": {
        "slug": "face_with_diagonal_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😟": {
        "slug": "worried_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙁": {
        "slug": "slightly_frowning_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "☹️": {
        "slug": "frowning_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😮": {
        "slug": "face_with_open_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😯": {
        "slug": "hushed_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😲": {
        "slug": "astonished_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😳": {
        "slug": "flushed_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥺": {
        "slug": "pleading_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥹": {
        "slug": "face_holding_back_tears",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😦": {
        "slug": "frowning_face_with_open_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😧": {
        "slug": "anguished_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😨": {
        "slug": "fearful_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😰": {
        "slug": "anxious_face_with_sweat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😥": {
        "slug": "sad_but_relieved_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😢": {
        "slug": "crying_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😭": {
        "slug": "loudly_crying_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😱": {
        "slug": "face_screaming_in_fear",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😖": {
        "slug": "confounded_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😣": {
        "slug": "persevering_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😞": {
        "slug": "disappointed_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😓": {
        "slug": "downcast_face_with_sweat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😩": {
        "slug": "weary_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😫": {
        "slug": "tired_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🥱": {
        "slug": "yawning_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😤": {
        "slug": "face_with_steam_from_nose",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😡": {
        "slug": "enraged_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😠": {
        "slug": "angry_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤬": {
        "slug": "face_with_symbols_on_mouth",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😈": {
        "slug": "smiling_face_with_horns",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👿": {
        "slug": "angry_face_with_horns",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💀": {
        "slug": "skull",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "☠️": {
        "slug": "skull_and_crossbones",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💩": {
        "slug": "pile_of_poo",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤡": {
        "slug": "clown_face",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👹": {
        "slug": "ogre",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👺": {
        "slug": "goblin",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👻": {
        "slug": "ghost",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👽": {
        "slug": "alien",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👾": {
        "slug": "alien_monster",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤖": {
        "slug": "robot",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😺": {
        "slug": "grinning_cat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😸": {
        "slug": "grinning_cat_with_smiling_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😹": {
        "slug": "cat_with_tears_of_joy",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😻": {
        "slug": "smiling_cat_with_heart_eyes",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😼": {
        "slug": "cat_with_wry_smile",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😽": {
        "slug": "kissing_cat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙀": {
        "slug": "weary_cat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😿": {
        "slug": "crying_cat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "😾": {
        "slug": "pouting_cat",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙈": {
        "slug": "see_no_evil_monkey",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙉": {
        "slug": "hear_no_evil_monkey",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🙊": {
        "slug": "speak_no_evil_monkey",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💌": {
        "slug": "love_letter",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💘": {
        "slug": "heart_with_arrow",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💝": {
        "slug": "heart_with_ribbon",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💖": {
        "slug": "sparkling_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💗": {
        "slug": "growing_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💓": {
        "slug": "beating_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💞": {
        "slug": "revolving_hearts",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💕": {
        "slug": "two_hearts",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💟": {
        "slug": "heart_decoration",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "❣️": {
        "slug": "heart_exclamation",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💔": {
        "slug": "broken_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "❤️‍🔥": {
        "slug": "heart_on_fire",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "❤️‍🩹": {
        "slug": "mending_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "❤️": {
        "slug": "red_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🩷": {
        "slug": "pink_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🧡": {
        "slug": "orange_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💛": {
        "slug": "yellow_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💚": {
        "slug": "green_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💙": {
        "slug": "blue_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🩵": {
        "slug": "light_blue_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💜": {
        "slug": "purple_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤎": {
        "slug": "brown_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🖤": {
        "slug": "black_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🩶": {
        "slug": "grey_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🤍": {
        "slug": "white_heart",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💋": {
        "slug": "kiss_mark",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💯": {
        "slug": "hundred_points",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💢": {
        "slug": "anger_symbol",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💥": {
        "slug": "collision",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💫": {
        "slug": "dizzy",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💦": {
        "slug": "sweat_droplets",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💨": {
        "slug": "dashing_away",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🕳️": {
        "slug": "hole",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💬": {
        "slug": "speech_balloon",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👁️‍🗨️": {
        "slug": "eye_in_speech_bubble",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🗨️": {
        "slug": "left_speech_bubble",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "🗯️": {
        "slug": "right_anger_bubble",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💭": {
        "slug": "thought_balloon",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "💤": {
        "slug": "zzz",
        "group": "Smileys & Emotion",
        "skin": false
    },
    "👋": {
        "slug": "waving_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🤚": {
        "slug": "raised_back_of_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🖐️": {
        "slug": "hand_with_fingers_splayed",
        "group": "People & Body",
        "skin": true,

    },
    "✋": {
        "slug": "raised_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🖖": {
        "slug": "vulcan_salute",
        "group": "People & Body",
        "skin": true,

    },
    "🫱": {
        "slug": "rightwards_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🫲": {
        "slug": "leftwards_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🫳": {
        "slug": "palm_down_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🫴": {
        "slug": "palm_up_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🫷": {
        "slug": "leftwards_pushing_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🫸": {
        "slug": "rightwards_pushing_hand",
        "group": "People & Body",
        "skin": true,

    },
    "👌": {
        "slug": "ok_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🤌": {
        "slug": "pinched_fingers",
        "group": "People & Body",
        "skin": true,

    },
    "🤏": {
        "slug": "pinching_hand",
        "group": "People & Body",
        "skin": true,

    },
    "✌️": {
        "slug": "victory_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🤞": {
        "slug": "crossed_fingers",
        "group": "People & Body",
        "skin": true,

    },
    "🫰": {
        "slug": "hand_with_index_finger_and_thumb_crossed",
        "group": "People & Body",
        "skin": true,

    },
    "🤟": {
        "slug": "love_you_gesture",
        "group": "People & Body",
        "skin": true,

    },
    "🤘": {
        "slug": "sign_of_the_horns",
        "group": "People & Body",
        "skin": true,

    },
    "🤙": {
        "slug": "call_me_hand",
        "group": "People & Body",
        "skin": true,

    },
    "👈": {
        "slug": "backhand_index_pointing_left",
        "group": "People & Body",
        "skin": true,

    },
    "👉": {
        "slug": "backhand_index_pointing_right",
        "group": "People & Body",
        "skin": true,

    },
    "👆": {
        "slug": "backhand_index_pointing_up",
        "group": "People & Body",
        "skin": true,

    },
    "🖕": {
        "slug": "middle_finger",
        "group": "People & Body",
        "skin": true,

    },
    "👇": {
        "slug": "backhand_index_pointing_down",
        "group": "People & Body",
        "skin": true,

    },
    "☝️": {
        "slug": "index_pointing_up",
        "group": "People & Body",
        "skin": true,

    },
    "🫵": {
        "slug": "index_pointing_at_the_viewer",
        "group": "People & Body",
        "skin": true,

    },
    "👍": {
        "slug": "thumbs_up",
        "group": "People & Body",
        "skin": true,

    },
    "👎": {
        "slug": "thumbs_down",
        "group": "People & Body",
        "skin": true,

    },
    "✊": {
        "slug": "raised_fist",
        "group": "People & Body",
        "skin": true,

    },
    "👊": {
        "slug": "oncoming_fist",
        "group": "People & Body",
        "skin": true,

    },
    "🤛": {
        "slug": "left_facing_fist",
        "group": "People & Body",
        "skin": true,

    },
    "🤜": {
        "slug": "right_facing_fist",
        "group": "People & Body",
        "skin": true,

    },
    "👏": {
        "slug": "clapping_hands",
        "group": "People & Body",
        "skin": true,

    },
    "🙌": {
        "slug": "raising_hands",
        "group": "People & Body",
        "skin": true,

    },
    "🫶": {
        "slug": "heart_hands",
        "group": "People & Body",
        "skin": true,

    },
    "👐": {
        "slug": "open_hands",
        "group": "People & Body",
        "skin": true,

    },
    "🤲": {
        "slug": "palms_up_together",
        "group": "People & Body",
        "skin": true,

    },
    "🤝": {
        "slug": "handshake",
        "group": "People & Body",
        "skin": true,

    },
    "🙏": {
        "slug": "folded_hands",
        "group": "People & Body",
        "skin": true,

    },
    "✍️": {
        "slug": "writing_hand",
        "group": "People & Body",
        "skin": true,

    },
    "💅": {
        "slug": "nail_polish",
        "group": "People & Body",
        "skin": true,

    },
    "🤳": {
        "slug": "selfie",
        "group": "People & Body",
        "skin": true,

    },
    "💪": {
        "slug": "flexed_biceps",
        "group": "People & Body",
        "skin": true,

    },
    "🦾": {
        "slug": "mechanical_arm",
        "group": "People & Body",
        "skin": false
    },
    "🦿": {
        "slug": "mechanical_leg",
        "group": "People & Body",
        "skin": false
    },
    "🦵": {
        "slug": "leg",
        "group": "People & Body",
        "skin": true,

    },
    "🦶": {
        "slug": "foot",
        "group": "People & Body",
        "skin": true,

    },
    "👂": {
        "slug": "ear",
        "group": "People & Body",
        "skin": true,

    },
    "🦻": {
        "slug": "ear_with_hearing_aid",
        "group": "People & Body",
        "skin": true,

    },
    "👃": {
        "slug": "nose",
        "group": "People & Body",
        "skin": true,

    },
    "🧠": {
        "slug": "brain",
        "group": "People & Body",
        "skin": false
    },
    "🫀": {
        "slug": "anatomical_heart",
        "group": "People & Body",
        "skin": false
    },
    "🫁": {
        "slug": "lungs",
        "group": "People & Body",
        "skin": false
    },
    "🦷": {
        "slug": "tooth",
        "group": "People & Body",
        "skin": false
    },
    "🦴": {
        "slug": "bone",
        "group": "People & Body",
        "skin": false
    },
    "👀": {
        "slug": "eyes",
        "group": "People & Body",
        "skin": false
    },
    "👁️": {
        "slug": "eye",
        "group": "People & Body",
        "skin": false
    },
    "👅": {
        "slug": "tongue",
        "group": "People & Body",
        "skin": false
    },
    "👄": {
        "slug": "mouth",
        "group": "People & Body",
        "skin": false
    },
    "🫦": {
        "slug": "biting_lip",
        "group": "People & Body",
        "skin": false
    },
    "👶": {
        "slug": "baby",
        "group": "People & Body",
        "skin": true,

    },
    "🧒": {
        "slug": "child",
        "group": "People & Body",
        "skin": true,

    },
    "👦": {
        "slug": "boy",
        "group": "People & Body",
        "skin": true,

    },
    "👧": {
        "slug": "girl",
        "group": "People & Body",
        "skin": true,

    },
    "🧑": {
        "slug": "person",
        "group": "People & Body",
        "skin": true,

    },
    "👱": {
        "slug": "person_blond_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👨": {
        "slug": "man",
        "group": "People & Body",
        "skin": true,

    },
    "🧔": {
        "slug": "person_beard",
        "group": "People & Body",
        "skin": true,

    },
    "🧔‍♂️": {
        "slug": "man_beard",
        "group": "People & Body",
        "skin": true,

    },
    "🧔‍♀️": {
        "slug": "woman_beard",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦰": {
        "slug": "man_red_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦱": {
        "slug": "man_curly_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦳": {
        "slug": "man_white_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦲": {
        "slug": "man_bald",
        "group": "People & Body",
        "skin": true,

    },
    "👩": {
        "slug": "woman",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦰": {
        "slug": "woman_red_hair",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦰": {
        "slug": "person_red_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦱": {
        "slug": "woman_curly_hair",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦱": {
        "slug": "person_curly_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦳": {
        "slug": "woman_white_hair",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦳": {
        "slug": "person_white_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦲": {
        "slug": "woman_bald",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦲": {
        "slug": "person_bald",
        "group": "People & Body",
        "skin": true,

    },
    "👱‍♀️": {
        "slug": "woman_blond_hair",
        "group": "People & Body",
        "skin": true,

    },
    "👱‍♂️": {
        "slug": "man_blond_hair",
        "group": "People & Body",
        "skin": true,

    },
    "🧓": {
        "slug": "older_person",
        "group": "People & Body",
        "skin": true,

    },
    "👴": {
        "slug": "old_man",
        "group": "People & Body",
        "skin": true,

    },
    "👵": {
        "slug": "old_woman",
        "group": "People & Body",
        "skin": true,

    },
    "🙍": {
        "slug": "person_frowning",
        "group": "People & Body",
        "skin": true,

    },
    "🙍‍♂️": {
        "slug": "man_frowning",
        "group": "People & Body",
        "skin": true,

    },
    "🙍‍♀️": {
        "slug": "woman_frowning",
        "group": "People & Body",
        "skin": true,

    },
    "🙎": {
        "slug": "person_pouting",
        "group": "People & Body",
        "skin": true,

    },
    "🙎‍♂️": {
        "slug": "man_pouting",
        "group": "People & Body",
        "skin": true,

    },
    "🙎‍♀️": {
        "slug": "woman_pouting",
        "group": "People & Body",
        "skin": true,

    },
    "🙅": {
        "slug": "person_gesturing_no",
        "group": "People & Body",
        "skin": true,

    },
    "🙅‍♂️": {
        "slug": "man_gesturing_no",
        "group": "People & Body",
        "skin": true,

    },
    "🙅‍♀️": {
        "slug": "woman_gesturing_no",
        "group": "People & Body",
        "skin": true,

    },
    "🙆": {
        "slug": "person_gesturing_ok",
        "group": "People & Body",
        "skin": true,

    },
    "🙆‍♂️": {
        "slug": "man_gesturing_ok",
        "group": "People & Body",
        "skin": true,

    },
    "🙆‍♀️": {
        "slug": "woman_gesturing_ok",
        "group": "People & Body",
        "skin": true,

    },
    "💁": {
        "slug": "person_tipping_hand",
        "group": "People & Body",
        "skin": true,

    },
    "💁‍♂️": {
        "slug": "man_tipping_hand",
        "group": "People & Body",
        "skin": true,

    },
    "💁‍♀️": {
        "slug": "woman_tipping_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🙋": {
        "slug": "person_raising_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🙋‍♂️": {
        "slug": "man_raising_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🙋‍♀️": {
        "slug": "woman_raising_hand",
        "group": "People & Body",
        "skin": true,

    },
    "🧏": {
        "slug": "deaf_person",
        "group": "People & Body",
        "skin": true,

    },
    "🧏‍♂️": {
        "slug": "deaf_man",
        "group": "People & Body",
        "skin": true,

    },
    "🧏‍♀️": {
        "slug": "deaf_woman",
        "group": "People & Body",
        "skin": true,

    },
    "🙇": {
        "slug": "person_bowing",
        "group": "People & Body",
        "skin": true,

    },
    "🙇‍♂️": {
        "slug": "man_bowing",
        "group": "People & Body",
        "skin": true,

    },
    "🙇‍♀️": {
        "slug": "woman_bowing",
        "group": "People & Body",
        "skin": true,

    },
    "🤦": {
        "slug": "person_facepalming",
        "group": "People & Body",
        "skin": true,

    },
    "🤦‍♂️": {
        "slug": "man_facepalming",
        "group": "People & Body",
        "skin": true,

    },
    "🤦‍♀️": {
        "slug": "woman_facepalming",
        "group": "People & Body",
        "skin": true,

    },
    "🤷": {
        "slug": "person_shrugging",
        "group": "People & Body",
        "skin": true,

    },
    "🤷‍♂️": {
        "slug": "man_shrugging",
        "group": "People & Body",
        "skin": true,

    },
    "🤷‍♀️": {
        "slug": "woman_shrugging",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍⚕️": {
        "slug": "health_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍⚕️": {
        "slug": "man_health_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍⚕️": {
        "slug": "woman_health_worker",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🎓": {
        "slug": "student",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🎓": {
        "slug": "man_student",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🎓": {
        "slug": "woman_student",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🏫": {
        "slug": "teacher",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🏫": {
        "slug": "man_teacher",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🏫": {
        "slug": "woman_teacher",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍⚖️": {
        "slug": "judge",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍⚖️": {
        "slug": "man_judge",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍⚖️": {
        "slug": "woman_judge",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🌾": {
        "slug": "farmer",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🌾": {
        "slug": "man_farmer",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🌾": {
        "slug": "woman_farmer",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🍳": {
        "slug": "cook",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🍳": {
        "slug": "man_cook",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🍳": {
        "slug": "woman_cook",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🔧": {
        "slug": "mechanic",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🔧": {
        "slug": "man_mechanic",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🔧": {
        "slug": "woman_mechanic",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🏭": {
        "slug": "factory_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🏭": {
        "slug": "man_factory_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🏭": {
        "slug": "woman_factory_worker",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍💼": {
        "slug": "office_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍💼": {
        "slug": "man_office_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍💼": {
        "slug": "woman_office_worker",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🔬": {
        "slug": "scientist",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🔬": {
        "slug": "man_scientist",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🔬": {
        "slug": "woman_scientist",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍💻": {
        "slug": "technologist",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍💻": {
        "slug": "man_technologist",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍💻": {
        "slug": "woman_technologist",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🎤": {
        "slug": "singer",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🎤": {
        "slug": "man_singer",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🎤": {
        "slug": "woman_singer",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🎨": {
        "slug": "artist",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🎨": {
        "slug": "man_artist",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🎨": {
        "slug": "woman_artist",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍✈️": {
        "slug": "pilot",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍✈️": {
        "slug": "man_pilot",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍✈️": {
        "slug": "woman_pilot",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🚀": {
        "slug": "astronaut",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🚀": {
        "slug": "man_astronaut",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🚀": {
        "slug": "woman_astronaut",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🚒": {
        "slug": "firefighter",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🚒": {
        "slug": "man_firefighter",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🚒": {
        "slug": "woman_firefighter",
        "group": "People & Body",
        "skin": true,

    },
    "👮": {
        "slug": "police_officer",
        "group": "People & Body",
        "skin": true,

    },
    "👮‍♂️": {
        "slug": "man_police_officer",
        "group": "People & Body",
        "skin": true,

    },
    "👮‍♀️": {
        "slug": "woman_police_officer",
        "group": "People & Body",
        "skin": true,

    },
    "🕵️": {
        "slug": "detective",
        "group": "People & Body",
        "skin": true,

    },
    "🕵️‍♂️": {
        "slug": "man_detective",
        "group": "People & Body",
        "skin": true,

    },
    "🕵️‍♀️": {
        "slug": "woman_detective",
        "group": "People & Body",
        "skin": true,

    },
    "💂": {
        "slug": "guard",
        "group": "People & Body",
        "skin": true,

    },
    "💂‍♂️": {
        "slug": "man_guard",
        "group": "People & Body",
        "skin": true,

    },
    "💂‍♀️": {
        "slug": "woman_guard",
        "group": "People & Body",
        "skin": true,

    },
    "🥷": {
        "slug": "ninja",
        "group": "People & Body",
        "skin": true,

    },
    "👷": {
        "slug": "construction_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👷‍♂️": {
        "slug": "man_construction_worker",
        "group": "People & Body",
        "skin": true,

    },
    "👷‍♀️": {
        "slug": "woman_construction_worker",
        "group": "People & Body",
        "skin": true,

    },
    "🫅": {
        "slug": "person_with_crown",
        "group": "People & Body",
        "skin": true,

    },
    "🤴": {
        "slug": "prince",
        "group": "People & Body",
        "skin": true,

    },
    "👸": {
        "slug": "princess",
        "group": "People & Body",
        "skin": true,

    },
    "👳": {
        "slug": "person_wearing_turban",
        "group": "People & Body",
        "skin": true,

    },
    "👳‍♂️": {
        "slug": "man_wearing_turban",
        "group": "People & Body",
        "skin": true,

    },
    "👳‍♀️": {
        "slug": "woman_wearing_turban",
        "group": "People & Body",
        "skin": true,

    },
    "👲": {
        "slug": "person_with_skullcap",
        "group": "People & Body",
        "skin": true,

    },
    "🧕": {
        "slug": "woman_with_headscarf",
        "group": "People & Body",
        "skin": true,

    },
    "🤵": {
        "slug": "person_in_tuxedo",
        "group": "People & Body",
        "skin": true,

    },
    "🤵‍♂️": {
        "slug": "man_in_tuxedo",
        "group": "People & Body",
        "skin": true,

    },
    "🤵‍♀️": {
        "slug": "woman_in_tuxedo",
        "group": "People & Body",
        "skin": true,

    },
    "👰": {
        "slug": "person_with_veil",
        "group": "People & Body",
        "skin": true,

    },
    "👰‍♂️": {
        "slug": "man_with_veil",
        "group": "People & Body",
        "skin": true,

    },
    "👰‍♀️": {
        "slug": "woman_with_veil",
        "group": "People & Body",
        "skin": true,

    },
    "🤰": {
        "slug": "pregnant_woman",
        "group": "People & Body",
        "skin": true,

    },
    "🫃": {
        "slug": "pregnant_man",
        "group": "People & Body",
        "skin": true,

    },
    "🫄": {
        "slug": "pregnant_person",
        "group": "People & Body",
        "skin": true,

    },
    "🤱": {
        "slug": "breast_feeding",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🍼": {
        "slug": "woman_feeding_baby",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🍼": {
        "slug": "man_feeding_baby",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🍼": {
        "slug": "person_feeding_baby",
        "group": "People & Body",
        "skin": true,

    },
    "👼": {
        "slug": "baby_angel",
        "group": "People & Body",
        "skin": true,

    },
    "🎅": {
        "slug": "santa_claus",
        "group": "People & Body",
        "skin": true,

    },
    "🤶": {
        "slug": "mrs_claus",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🎄": {
        "slug": "mx_claus",
        "group": "People & Body",
        "skin": true,

    },
    "🦸": {
        "slug": "superhero",
        "group": "People & Body",
        "skin": true,

    },
    "🦸‍♂️": {
        "slug": "man_superhero",
        "group": "People & Body",
        "skin": true,

    },
    "🦸‍♀️": {
        "slug": "woman_superhero",
        "group": "People & Body",
        "skin": true,

    },
    "🦹": {
        "slug": "supervillain",
        "group": "People & Body",
        "skin": true,

    },
    "🦹‍♂️": {
        "slug": "man_supervillain",
        "group": "People & Body",
        "skin": true,

    },
    "🦹‍♀️": {
        "slug": "woman_supervillain",
        "group": "People & Body",
        "skin": true,

    },
    "🧙": {
        "slug": "mage",
        "group": "People & Body",
        "skin": true,

    },
    "🧙‍♂️": {
        "slug": "man_mage",
        "group": "People & Body",
        "skin": true,

    },
    "🧙‍♀️": {
        "slug": "woman_mage",
        "group": "People & Body",
        "skin": true,

    },
    "🧚": {
        "slug": "fairy",
        "group": "People & Body",
        "skin": true,

    },
    "🧚‍♂️": {
        "slug": "man_fairy",
        "group": "People & Body",
        "skin": true,

    },
    "🧚‍♀️": {
        "slug": "woman_fairy",
        "group": "People & Body",
        "skin": true,

    },
    "🧛": {
        "slug": "vampire",
        "group": "People & Body",
        "skin": true,

    },
    "🧛‍♂️": {
        "slug": "man_vampire",
        "group": "People & Body",
        "skin": true,

    },
    "🧛‍♀️": {
        "slug": "woman_vampire",
        "group": "People & Body",
        "skin": true,

    },
    "🧜": {
        "slug": "merperson",
        "group": "People & Body",
        "skin": true,

    },
    "🧜‍♂️": {
        "slug": "merman",
        "group": "People & Body",
        "skin": true,

    },
    "🧜‍♀️": {
        "slug": "mermaid",
        "group": "People & Body",
        "skin": true,

    },
    "🧝": {
        "slug": "elf",
        "group": "People & Body",
        "skin": true,

    },
    "🧝‍♂️": {
        "slug": "man_elf",
        "group": "People & Body",
        "skin": true,

    },
    "🧝‍♀️": {
        "slug": "woman_elf",
        "group": "People & Body",
        "skin": true,

    },
    "🧞": {
        "slug": "genie",
        "group": "People & Body",
        "skin": false
    },
    "🧞‍♂️": {
        "slug": "man_genie",
        "group": "People & Body",
        "skin": false
    },
    "🧞‍♀️": {
        "slug": "woman_genie",
        "group": "People & Body",
        "skin": false
    },
    "🧟": {
        "slug": "zombie",
        "group": "People & Body",
        "skin": false
    },
    "🧟‍♂️": {
        "slug": "man_zombie",
        "group": "People & Body",
        "skin": false
    },
    "🧟‍♀️": {
        "slug": "woman_zombie",
        "group": "People & Body",
        "skin": false
    },
    "🧌": {
        "slug": "troll",
        "group": "People & Body",
        "skin": false
    },
    "💆": {
        "slug": "person_getting_massage",
        "group": "People & Body",
        "skin": true,

    },
    "💆‍♂️": {
        "slug": "man_getting_massage",
        "group": "People & Body",
        "skin": true,

    },
    "💆‍♀️": {
        "slug": "woman_getting_massage",
        "group": "People & Body",
        "skin": true,

    },
    "💇": {
        "slug": "person_getting_haircut",
        "group": "People & Body",
        "skin": true,

    },
    "💇‍♂️": {
        "slug": "man_getting_haircut",
        "group": "People & Body",
        "skin": true,

    },
    "💇‍♀️": {
        "slug": "woman_getting_haircut",
        "group": "People & Body",
        "skin": true,

    },
    "🚶": {
        "slug": "person_walking",
        "group": "People & Body",
        "skin": true,

    },
    "🚶‍♂️": {
        "slug": "man_walking",
        "group": "People & Body",
        "skin": true,

    },
    "🚶‍♀️": {
        "slug": "woman_walking",
        "group": "People & Body",
        "skin": true,

    },
    "🧍": {
        "slug": "person_standing",
        "group": "People & Body",
        "skin": true,

    },
    "🧍‍♂️": {
        "slug": "man_standing",
        "group": "People & Body",
        "skin": true,

    },
    "🧍‍♀️": {
        "slug": "woman_standing",
        "group": "People & Body",
        "skin": true,

    },
    "🧎": {
        "slug": "person_kneeling",
        "group": "People & Body",
        "skin": true,

    },
    "🧎‍♂️": {
        "slug": "man_kneeling",
        "group": "People & Body",
        "skin": true,

    },
    "🧎‍♀️": {
        "slug": "woman_kneeling",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦯": {
        "slug": "person_with_white_cane",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦯": {
        "slug": "man_with_white_cane",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦯": {
        "slug": "woman_with_white_cane",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦼": {
        "slug": "person_in_motorized_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦼": {
        "slug": "man_in_motorized_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦼": {
        "slug": "woman_in_motorized_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🦽": {
        "slug": "person_in_manual_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍🦽": {
        "slug": "man_in_manual_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍🦽": {
        "slug": "woman_in_manual_wheelchair",
        "group": "People & Body",
        "skin": true,

    },
    "🏃": {
        "slug": "person_running",
        "group": "People & Body",
        "skin": true,

    },
    "🏃‍♂️": {
        "slug": "man_running",
        "group": "People & Body",
        "skin": true,

    },
    "🏃‍♀️": {
        "slug": "woman_running",
        "group": "People & Body",
        "skin": true,

    },
    "💃": {
        "slug": "woman_dancing",
        "group": "People & Body",
        "skin": true,

    },
    "🕺": {
        "slug": "man_dancing",
        "group": "People & Body",
        "skin": true,

    },
    "🕴️": {
        "slug": "person_in_suit_levitating",
        "group": "People & Body",
        "skin": true,

    },
    "👯": {
        "slug": "people_with_bunny_ears",
        "group": "People & Body",
        "skin": false
    },
    "👯‍♂️": {
        "slug": "men_with_bunny_ears",
        "group": "People & Body",
        "skin": false
    },
    "👯‍♀️": {
        "slug": "women_with_bunny_ears",
        "group": "People & Body",
        "skin": false
    },
    "🧖": {
        "slug": "person_in_steamy_room",
        "group": "People & Body",
        "skin": true,

    },
    "🧖‍♂️": {
        "slug": "man_in_steamy_room",
        "group": "People & Body",
        "skin": true,

    },
    "🧖‍♀️": {
        "slug": "woman_in_steamy_room",
        "group": "People & Body",
        "skin": true,

    },
    "🧗": {
        "slug": "person_climbing",
        "group": "People & Body",
        "skin": true,

    },
    "🧗‍♂️": {
        "slug": "man_climbing",
        "group": "People & Body",
        "skin": true,

    },
    "🧗‍♀️": {
        "slug": "woman_climbing",
        "group": "People & Body",
        "skin": true,

    },
    "🤺": {
        "slug": "person_fencing",
        "group": "People & Body",
        "skin": false
    },
    "🏇": {
        "slug": "horse_racing",
        "group": "People & Body",
        "skin": true,

    },
    "⛷️": {
        "slug": "skier",
        "group": "People & Body",
        "skin": false
    },
    "🏂": {
        "slug": "snowboarder",
        "group": "People & Body",
        "skin": true,

    },
    "🏌️": {
        "slug": "person_golfing",
        "group": "People & Body",
        "skin": true,

    },
    "🏌️‍♂️": {
        "slug": "man_golfing",
        "group": "People & Body",
        "skin": true,

    },
    "🏌️‍♀️": {
        "slug": "woman_golfing",
        "group": "People & Body",
        "skin": true,

    },
    "🏄": {
        "slug": "person_surfing",
        "group": "People & Body",
        "skin": true,

    },
    "🏄‍♂️": {
        "slug": "man_surfing",
        "group": "People & Body",
        "skin": true,

    },
    "🏄‍♀️": {
        "slug": "woman_surfing",
        "group": "People & Body",
        "skin": true,

    },
    "🚣": {
        "slug": "person_rowing_boat",
        "group": "People & Body",
        "skin": true,

    },
    "🚣‍♂️": {
        "slug": "man_rowing_boat",
        "group": "People & Body",
        "skin": true,

    },
    "🚣‍♀️": {
        "slug": "woman_rowing_boat",
        "group": "People & Body",
        "skin": true,

    },
    "🏊": {
        "slug": "person_swimming",
        "group": "People & Body",
        "skin": true,

    },
    "🏊‍♂️": {
        "slug": "man_swimming",
        "group": "People & Body",
        "skin": true,

    },
    "🏊‍♀️": {
        "slug": "woman_swimming",
        "group": "People & Body",
        "skin": true,

    },
    "⛹️": {
        "slug": "person_bouncing_ball",
        "group": "People & Body",
        "skin": true,

    },
    "⛹️‍♂️": {
        "slug": "man_bouncing_ball",
        "group": "People & Body",
        "skin": true,

    },
    "⛹️‍♀️": {
        "slug": "woman_bouncing_ball",
        "group": "People & Body",
        "skin": true,

    },
    "🏋️": {
        "slug": "person_lifting_weights",
        "group": "People & Body",
        "skin": true,

    },
    "🏋️‍♂️": {
        "slug": "man_lifting_weights",
        "group": "People & Body",
        "skin": true,

    },
    "🏋️‍♀️": {
        "slug": "woman_lifting_weights",
        "group": "People & Body",
        "skin": true,

    },
    "🚴": {
        "slug": "person_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🚴‍♂️": {
        "slug": "man_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🚴‍♀️": {
        "slug": "woman_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🚵": {
        "slug": "person_mountain_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🚵‍♂️": {
        "slug": "man_mountain_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🚵‍♀️": {
        "slug": "woman_mountain_biking",
        "group": "People & Body",
        "skin": true,

    },
    "🤸": {
        "slug": "person_cartwheeling",
        "group": "People & Body",
        "skin": true,

    },
    "🤸‍♂️": {
        "slug": "man_cartwheeling",
        "group": "People & Body",
        "skin": true,

    },
    "🤸‍♀️": {
        "slug": "woman_cartwheeling",
        "group": "People & Body",
        "skin": true,

    },
    "🤼": {
        "slug": "people_wrestling",
        "group": "People & Body",
        "skin": false
    },
    "🤼‍♂️": {
        "slug": "men_wrestling",
        "group": "People & Body",
        "skin": false
    },
    "🤼‍♀️": {
        "slug": "women_wrestling",
        "group": "People & Body",
        "skin": false
    },
    "🤽": {
        "slug": "person_playing_water_polo",
        "group": "People & Body",
        "skin": true,

    },
    "🤽‍♂️": {
        "slug": "man_playing_water_polo",
        "group": "People & Body",
        "skin": true,

    },
    "🤽‍♀️": {
        "slug": "woman_playing_water_polo",
        "group": "People & Body",
        "skin": true,

    },
    "🤾": {
        "slug": "person_playing_handball",
        "group": "People & Body",
        "skin": true,

    },
    "🤾‍♂️": {
        "slug": "man_playing_handball",
        "group": "People & Body",
        "skin": true,

    },
    "🤾‍♀️": {
        "slug": "woman_playing_handball",
        "group": "People & Body",
        "skin": true,

    },
    "🤹": {
        "slug": "person_juggling",
        "group": "People & Body",
        "skin": true,

    },
    "🤹‍♂️": {
        "slug": "man_juggling",
        "group": "People & Body",
        "skin": true,

    },
    "🤹‍♀️": {
        "slug": "woman_juggling",
        "group": "People & Body",
        "skin": true,

    },
    "🧘": {
        "slug": "person_in_lotus_position",
        "group": "People & Body",
        "skin": true,

    },
    "🧘‍♂️": {
        "slug": "man_in_lotus_position",
        "group": "People & Body",
        "skin": true,

    },
    "🧘‍♀️": {
        "slug": "woman_in_lotus_position",
        "group": "People & Body",
        "skin": true,

    },
    "🛀": {
        "slug": "person_taking_bath",
        "group": "People & Body",
        "skin": true,

    },
    "🛌": {
        "slug": "person_in_bed",
        "group": "People & Body",
        "skin": true,

    },
    "🧑‍🤝‍🧑": {
        "slug": "people_holding_hands",
        "group": "People & Body",
        "skin": true,

    },
    "👭": {
        "slug": "women_holding_hands",
        "group": "People & Body",
        "skin": true,

    },
    "👫": {
        "slug": "woman_and_man_holding_hands",
        "group": "People & Body",
        "skin": true,

    },
    "👬": {
        "slug": "men_holding_hands",
        "group": "People & Body",
        "skin": true,

    },
    "💏": {
        "slug": "kiss",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍❤️‍💋‍👨": {
        "slug": "kiss_woman_man",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍❤️‍💋‍👨": {
        "slug": "kiss_man_man",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍❤️‍💋‍👩": {
        "slug": "kiss_woman_woman",
        "group": "People & Body",
        "skin": true,

    },
    "💑": {
        "slug": "couple_with_heart",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍❤️‍👨": {
        "slug": "couple_with_heart_woman_man",
        "group": "People & Body",
        "skin": true,

    },
    "👨‍❤️‍👨": {
        "slug": "couple_with_heart_man_man",
        "group": "People & Body",
        "skin": true,

    },
    "👩‍❤️‍👩": {
        "slug": "couple_with_heart_woman_woman",
        "group": "People & Body",
        "skin": true,

    },
    "👪": {
        "slug": "family",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👩‍👦": {
        "slug": "family_man_woman_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👩‍👧": {
        "slug": "family_man_woman_girl",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👩‍👧‍👦": {
        "slug": "family_man_woman_girl_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👩‍👦‍👦": {
        "slug": "family_man_woman_boy_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👩‍👧‍👧": {
        "slug": "family_man_woman_girl_girl",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👨‍👦": {
        "slug": "family_man_man_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👨‍👧": {
        "slug": "family_man_man_girl",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👨‍👧‍👦": {
        "slug": "family_man_man_girl_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👨‍👦‍👦": {
        "slug": "family_man_man_boy_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👨‍👧‍👧": {
        "slug": "family_man_man_girl_girl",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👩‍👦": {
        "slug": "family_woman_woman_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👩‍👧": {
        "slug": "family_woman_woman_girl",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👩‍👧‍👦": {
        "slug": "family_woman_woman_girl_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👩‍👦‍👦": {
        "slug": "family_woman_woman_boy_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👩‍👧‍👧": {
        "slug": "family_woman_woman_girl_girl",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👦": {
        "slug": "family_man_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👦‍👦": {
        "slug": "family_man_boy_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👧": {
        "slug": "family_man_girl",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👧‍👦": {
        "slug": "family_man_girl_boy",
        "group": "People & Body",
        "skin": false
    },
    "👨‍👧‍👧": {
        "slug": "family_man_girl_girl",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👦": {
        "slug": "family_woman_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👦‍👦": {
        "slug": "family_woman_boy_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👧": {
        "slug": "family_woman_girl",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👧‍👦": {
        "slug": "family_woman_girl_boy",
        "group": "People & Body",
        "skin": false
    },
    "👩‍👧‍👧": {
        "slug": "family_woman_girl_girl",
        "group": "People & Body",
        "skin": false
    },
    "🗣️": {
        "slug": "speaking_head",
        "group": "People & Body",
        "skin": false
    },
    "👤": {
        "slug": "bust_in_silhouette",
        "group": "People & Body",
        "skin": false
    },
    "👥": {
        "slug": "busts_in_silhouette",
        "group": "People & Body",
        "skin": false
    },
    "🫂": {
        "slug": "people_hugging",
        "group": "People & Body",
        "skin": false
    },
    "👣": {
        "slug": "footprints",
        "group": "People & Body",
        "skin": false
    },
    "🐵": {
        "slug": "monkey_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐒": {
        "slug": "monkey",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦍": {
        "slug": "gorilla",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦧": {
        "slug": "orangutan",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐶": {
        "slug": "dog_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐕": {
        "slug": "dog",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦮": {
        "slug": "guide_dog",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐕‍🦺": {
        "slug": "service_dog",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐩": {
        "slug": "poodle",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐺": {
        "slug": "wolf",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦊": {
        "slug": "fox",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦝": {
        "slug": "raccoon",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐱": {
        "slug": "cat_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐈": {
        "slug": "cat",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐈‍⬛": {
        "slug": "black_cat",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦁": {
        "slug": "lion",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐯": {
        "slug": "tiger_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐅": {
        "slug": "tiger",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐆": {
        "slug": "leopard",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐴": {
        "slug": "horse_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🫎": {
        "slug": "moose",
        "group": "Animals & Nature",
        "skin": false
    },
    "🫏": {
        "slug": "donkey",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐎": {
        "slug": "horse",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦄": {
        "slug": "unicorn",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦓": {
        "slug": "zebra",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦌": {
        "slug": "deer",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦬": {
        "slug": "bison",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐮": {
        "slug": "cow_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐂": {
        "slug": "ox",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐃": {
        "slug": "water_buffalo",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐄": {
        "slug": "cow",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐷": {
        "slug": "pig_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐖": {
        "slug": "pig",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐗": {
        "slug": "boar",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐽": {
        "slug": "pig_nose",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐏": {
        "slug": "ram",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐑": {
        "slug": "ewe",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐐": {
        "slug": "goat",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐪": {
        "slug": "camel",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐫": {
        "slug": "two_hump_camel",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦙": {
        "slug": "llama",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦒": {
        "slug": "giraffe",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐘": {
        "slug": "elephant",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦣": {
        "slug": "mammoth",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦏": {
        "slug": "rhinoceros",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦛": {
        "slug": "hippopotamus",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐭": {
        "slug": "mouse_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐁": {
        "slug": "mouse",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐀": {
        "slug": "rat",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐹": {
        "slug": "hamster",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐰": {
        "slug": "rabbit_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐇": {
        "slug": "rabbit",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐿️": {
        "slug": "chipmunk",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦫": {
        "slug": "beaver",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦔": {
        "slug": "hedgehog",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦇": {
        "slug": "bat",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐻": {
        "slug": "bear",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐻‍❄️": {
        "slug": "polar_bear",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐨": {
        "slug": "koala",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐼": {
        "slug": "panda",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦥": {
        "slug": "sloth",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦦": {
        "slug": "otter",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦨": {
        "slug": "skunk",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦘": {
        "slug": "kangaroo",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦡": {
        "slug": "badger",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐾": {
        "slug": "paw_prints",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦃": {
        "slug": "turkey",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐔": {
        "slug": "chicken",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐓": {
        "slug": "rooster",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐣": {
        "slug": "hatching_chick",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐤": {
        "slug": "baby_chick",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐥": {
        "slug": "front_facing_baby_chick",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐦": {
        "slug": "bird",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐧": {
        "slug": "penguin",
        "group": "Animals & Nature",
        "skin": false
    },
    "🕊️": {
        "slug": "dove",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦅": {
        "slug": "eagle",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦆": {
        "slug": "duck",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦢": {
        "slug": "swan",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦉": {
        "slug": "owl",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦤": {
        "slug": "dodo",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪶": {
        "slug": "feather",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦩": {
        "slug": "flamingo",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦚": {
        "slug": "peacock",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦜": {
        "slug": "parrot",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪽": {
        "slug": "wing",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐦‍⬛": {
        "slug": "black_bird",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪿": {
        "slug": "goose",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐸": {
        "slug": "frog",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐊": {
        "slug": "crocodile",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐢": {
        "slug": "turtle",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦎": {
        "slug": "lizard",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐍": {
        "slug": "snake",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐲": {
        "slug": "dragon_face",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐉": {
        "slug": "dragon",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦕": {
        "slug": "sauropod",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦖": {
        "slug": "t_rex",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐳": {
        "slug": "spouting_whale",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐋": {
        "slug": "whale",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐬": {
        "slug": "dolphin",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦭": {
        "slug": "seal",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐟": {
        "slug": "fish",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐠": {
        "slug": "tropical_fish",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐡": {
        "slug": "blowfish",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦈": {
        "slug": "shark",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐙": {
        "slug": "octopus",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐚": {
        "slug": "spiral_shell",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪸": {
        "slug": "coral",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪼": {
        "slug": "jellyfish",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐌": {
        "slug": "snail",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦋": {
        "slug": "butterfly",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐛": {
        "slug": "bug",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐜": {
        "slug": "ant",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐝": {
        "slug": "honeybee",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪲": {
        "slug": "beetle",
        "group": "Animals & Nature",
        "skin": false
    },
    "🐞": {
        "slug": "lady_beetle",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦗": {
        "slug": "cricket",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪳": {
        "slug": "cockroach",
        "group": "Animals & Nature",
        "skin": false
    },
    "🕷️": {
        "slug": "spider",
        "group": "Animals & Nature",
        "skin": false
    },
    "🕸️": {
        "slug": "spider_web",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦂": {
        "slug": "scorpion",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦟": {
        "slug": "mosquito",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪰": {
        "slug": "fly",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪱": {
        "slug": "worm",
        "group": "Animals & Nature",
        "skin": false
    },
    "🦠": {
        "slug": "microbe",
        "group": "Animals & Nature",
        "skin": false
    },
    "💐": {
        "slug": "bouquet",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌸": {
        "slug": "cherry_blossom",
        "group": "Animals & Nature",
        "skin": false
    },
    "💮": {
        "slug": "white_flower",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪷": {
        "slug": "lotus",
        "group": "Animals & Nature",
        "skin": false
    },
    "🏵️": {
        "slug": "rosette",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌹": {
        "slug": "rose",
        "group": "Animals & Nature",
        "skin": false
    },
    "🥀": {
        "slug": "wilted_flower",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌺": {
        "slug": "hibiscus",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌻": {
        "slug": "sunflower",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌼": {
        "slug": "blossom",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌷": {
        "slug": "tulip",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪻": {
        "slug": "hyacinth",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌱": {
        "slug": "seedling",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪴": {
        "slug": "potted_plant",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌲": {
        "slug": "evergreen_tree",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌳": {
        "slug": "deciduous_tree",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌴": {
        "slug": "palm_tree",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌵": {
        "slug": "cactus",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌾": {
        "slug": "sheaf_of_rice",
        "group": "Animals & Nature",
        "skin": false
    },
    "🌿": {
        "slug": "herb",
        "group": "Animals & Nature",
        "skin": false
    },
    "☘️": {
        "slug": "shamrock",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍀": {
        "slug": "four_leaf_clover",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍁": {
        "slug": "maple_leaf",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍂": {
        "slug": "fallen_leaf",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍃": {
        "slug": "leaf_fluttering_in_wind",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪹": {
        "slug": "empty_nest",
        "group": "Animals & Nature",
        "skin": false
    },
    "🪺": {
        "slug": "nest_with_eggs",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍄": {
        "slug": "mushroom",
        "group": "Animals & Nature",
        "skin": false
    },
    "🍇": {
        "slug": "grapes",
        "group": "Food & Drink",
        "skin": false
    },
    "🍈": {
        "slug": "melon",
        "group": "Food & Drink",
        "skin": false
    },
    "🍉": {
        "slug": "watermelon",
        "group": "Food & Drink",
        "skin": false
    },
    "🍊": {
        "slug": "tangerine",
        "group": "Food & Drink",
        "skin": false
    },
    "🍋": {
        "slug": "lemon",
        "group": "Food & Drink",
        "skin": false
    },
    "🍌": {
        "slug": "banana",
        "group": "Food & Drink",
        "skin": false
    },
    "🍍": {
        "slug": "pineapple",
        "group": "Food & Drink",
        "skin": false
    },
    "🥭": {
        "slug": "mango",
        "group": "Food & Drink",
        "skin": false
    },
    "🍎": {
        "slug": "red_apple",
        "group": "Food & Drink",
        "skin": false
    },
    "🍏": {
        "slug": "green_apple",
        "group": "Food & Drink",
        "skin": false
    },
    "🍐": {
        "slug": "pear",
        "group": "Food & Drink",
        "skin": false
    },
    "🍑": {
        "slug": "peach",
        "group": "Food & Drink",
        "skin": false
    },
    "🍒": {
        "slug": "cherries",
        "group": "Food & Drink",
        "skin": false
    },
    "🍓": {
        "slug": "strawberry",
        "group": "Food & Drink",
        "skin": false
    },
    "🫐": {
        "slug": "blueberries",
        "group": "Food & Drink",
        "skin": false
    },
    "🥝": {
        "slug": "kiwi_fruit",
        "group": "Food & Drink",
        "skin": false
    },
    "🍅": {
        "slug": "tomato",
        "group": "Food & Drink",
        "skin": false
    },
    "🫒": {
        "slug": "olive",
        "group": "Food & Drink",
        "skin": false
    },
    "🥥": {
        "slug": "coconut",
        "group": "Food & Drink",
        "skin": false
    },
    "🥑": {
        "slug": "avocado",
        "group": "Food & Drink",
        "skin": false
    },
    "🍆": {
        "slug": "eggplant",
        "group": "Food & Drink",
        "skin": false
    },
    "🥔": {
        "slug": "potato",
        "group": "Food & Drink",
        "skin": false
    },
    "🥕": {
        "slug": "carrot",
        "group": "Food & Drink",
        "skin": false
    },
    "🌽": {
        "slug": "ear_of_corn",
        "group": "Food & Drink",
        "skin": false
    },
    "🌶️": {
        "slug": "hot_pepper",
        "group": "Food & Drink",
        "skin": false
    },
    "🫑": {
        "slug": "bell_pepper",
        "group": "Food & Drink",
        "skin": false
    },
    "🥒": {
        "slug": "cucumber",
        "group": "Food & Drink",
        "skin": false
    },
    "🥬": {
        "slug": "leafy_green",
        "group": "Food & Drink",
        "skin": false
    },
    "🥦": {
        "slug": "broccoli",
        "group": "Food & Drink",
        "skin": false
    },
    "🧄": {
        "slug": "garlic",
        "group": "Food & Drink",
        "skin": false
    },
    "🧅": {
        "slug": "onion",
        "group": "Food & Drink",
        "skin": false
    },
    "🥜": {
        "slug": "peanuts",
        "group": "Food & Drink",
        "skin": false
    },
    "🫘": {
        "slug": "beans",
        "group": "Food & Drink",
        "skin": false
    },
    "🌰": {
        "slug": "chestnut",
        "group": "Food & Drink",
        "skin": false
    },
    "🫚": {
        "slug": "ginger_root",
        "group": "Food & Drink",
        "skin": false
    },
    "🫛": {
        "slug": "pea_pod",
        "group": "Food & Drink",
        "skin": false
    },
    "🍞": {
        "slug": "bread",
        "group": "Food & Drink",
        "skin": false
    },
    "🥐": {
        "slug": "croissant",
        "group": "Food & Drink",
        "skin": false
    },
    "🥖": {
        "slug": "baguette_bread",
        "group": "Food & Drink",
        "skin": false
    },
    "🫓": {
        "slug": "flatbread",
        "group": "Food & Drink",
        "skin": false
    },
    "🥨": {
        "slug": "pretzel",
        "group": "Food & Drink",
        "skin": false
    },
    "🥯": {
        "slug": "bagel",
        "group": "Food & Drink",
        "skin": false
    },
    "🥞": {
        "slug": "pancakes",
        "group": "Food & Drink",
        "skin": false
    },
    "🧇": {
        "slug": "waffle",
        "group": "Food & Drink",
        "skin": false
    },
    "🧀": {
        "slug": "cheese_wedge",
        "group": "Food & Drink",
        "skin": false
    },
    "🍖": {
        "slug": "meat_on_bone",
        "group": "Food & Drink",
        "skin": false
    },
    "🍗": {
        "slug": "poultry_leg",
        "group": "Food & Drink",
        "skin": false
    },
    "🥩": {
        "slug": "cut_of_meat",
        "group": "Food & Drink",
        "skin": false
    },
    "🥓": {
        "slug": "bacon",
        "group": "Food & Drink",
        "skin": false
    },
    "🍔": {
        "slug": "hamburger",
        "group": "Food & Drink",
        "skin": false
    },
    "🍟": {
        "slug": "french_fries",
        "group": "Food & Drink",
        "skin": false
    },
    "🍕": {
        "slug": "pizza",
        "group": "Food & Drink",
        "skin": false
    },
    "🌭": {
        "slug": "hot_dog",
        "group": "Food & Drink",
        "skin": false
    },
    "🥪": {
        "slug": "sandwich",
        "group": "Food & Drink",
        "skin": false
    },
    "🌮": {
        "slug": "taco",
        "group": "Food & Drink",
        "skin": false
    },
    "🌯": {
        "slug": "burrito",
        "group": "Food & Drink",
        "skin": false
    },
    "🫔": {
        "slug": "tamale",
        "group": "Food & Drink",
        "skin": false
    },
    "🥙": {
        "slug": "stuffed_flatbread",
        "group": "Food & Drink",
        "skin": false
    },
    "🧆": {
        "slug": "falafel",
        "group": "Food & Drink",
        "skin": false
    },
    "🥚": {
        "slug": "egg",
        "group": "Food & Drink",
        "skin": false
    },
    "🍳": {
        "slug": "cooking",
        "group": "Food & Drink",
        "skin": false
    },
    "🥘": {
        "slug": "shallow_pan_of_food",
        "group": "Food & Drink",
        "skin": false
    },
    "🍲": {
        "slug": "pot_of_food",
        "group": "Food & Drink",
        "skin": false
    },
    "🫕": {
        "slug": "fondue",
        "group": "Food & Drink",
        "skin": false
    },
    "🥣": {
        "slug": "bowl_with_spoon",
        "group": "Food & Drink",
        "skin": false
    },
    "🥗": {
        "slug": "green_salad",
        "group": "Food & Drink",
        "skin": false
    },
    "🍿": {
        "slug": "popcorn",
        "group": "Food & Drink",
        "skin": false
    },
    "🧈": {
        "slug": "butter",
        "group": "Food & Drink",
        "skin": false
    },
    "🧂": {
        "slug": "salt",
        "group": "Food & Drink",
        "skin": false
    },
    "🥫": {
        "slug": "canned_food",
        "group": "Food & Drink",
        "skin": false
    },
    "🍱": {
        "slug": "bento_box",
        "group": "Food & Drink",
        "skin": false
    },
    "🍘": {
        "slug": "rice_cracker",
        "group": "Food & Drink",
        "skin": false
    },
    "🍙": {
        "slug": "rice_ball",
        "group": "Food & Drink",
        "skin": false
    },
    "🍚": {
        "slug": "cooked_rice",
        "group": "Food & Drink",
        "skin": false
    },
    "🍛": {
        "slug": "curry_rice",
        "group": "Food & Drink",
        "skin": false
    },
    "🍜": {
        "slug": "steaming_bowl",
        "group": "Food & Drink",
        "skin": false
    },
    "🍝": {
        "slug": "spaghetti",
        "group": "Food & Drink",
        "skin": false
    },
    "🍠": {
        "slug": "roasted_sweet_potato",
        "group": "Food & Drink",
        "skin": false
    },
    "🍢": {
        "slug": "oden",
        "group": "Food & Drink",
        "skin": false
    },
    "🍣": {
        "slug": "sushi",
        "group": "Food & Drink",
        "skin": false
    },
    "🍤": {
        "slug": "fried_shrimp",
        "group": "Food & Drink",
        "skin": false
    },
    "🍥": {
        "slug": "fish_cake_with_swirl",
        "group": "Food & Drink",
        "skin": false
    },
    "🥮": {
        "slug": "moon_cake",
        "group": "Food & Drink",
        "skin": false
    },
    "🍡": {
        "slug": "dango",
        "group": "Food & Drink",
        "skin": false
    },
    "🥟": {
        "slug": "dumpling",
        "group": "Food & Drink",
        "skin": false
    },
    "🥠": {
        "slug": "fortune_cookie",
        "group": "Food & Drink",
        "skin": false
    },
    "🥡": {
        "slug": "takeout_box",
        "group": "Food & Drink",
        "skin": false
    },
    "🦀": {
        "slug": "crab",
        "group": "Food & Drink",
        "skin": false
    },
    "🦞": {
        "slug": "lobster",
        "group": "Food & Drink",
        "skin": false
    },
    "🦐": {
        "slug": "shrimp",
        "group": "Food & Drink",
        "skin": false
    },
    "🦑": {
        "slug": "squid",
        "group": "Food & Drink",
        "skin": false
    },
    "🦪": {
        "slug": "oyster",
        "group": "Food & Drink",
        "skin": false
    },
    "🍦": {
        "slug": "soft_ice_cream",
        "group": "Food & Drink",
        "skin": false
    },
    "🍧": {
        "slug": "shaved_ice",
        "group": "Food & Drink",
        "skin": false
    },
    "🍨": {
        "slug": "ice_cream",
        "group": "Food & Drink",
        "skin": false
    },
    "🍩": {
        "slug": "doughnut",
        "group": "Food & Drink",
        "skin": false
    },
    "🍪": {
        "slug": "cookie",
        "group": "Food & Drink",
        "skin": false
    },
    "🎂": {
        "slug": "birthday_cake",
        "group": "Food & Drink",
        "skin": false
    },
    "🍰": {
        "slug": "shortcake",
        "group": "Food & Drink",
        "skin": false
    },
    "🧁": {
        "slug": "cupcake",
        "group": "Food & Drink",
        "skin": false
    },
    "🥧": {
        "slug": "pie",
        "group": "Food & Drink",
        "skin": false
    },
    "🍫": {
        "slug": "chocolate_bar",
        "group": "Food & Drink",
        "skin": false
    },
    "🍬": {
        "slug": "candy",
        "group": "Food & Drink",
        "skin": false
    },
    "🍭": {
        "slug": "lollipop",
        "group": "Food & Drink",
        "skin": false
    },
    "🍮": {
        "slug": "custard",
        "group": "Food & Drink",
        "skin": false
    },
    "🍯": {
        "slug": "honey_pot",
        "group": "Food & Drink",
        "skin": false
    },
    "🍼": {
        "slug": "baby_bottle",
        "group": "Food & Drink",
        "skin": false
    },
    "🥛": {
        "slug": "glass_of_milk",
        "group": "Food & Drink",
        "skin": false
    },
    "☕": {
        "slug": "hot_beverage",
        "group": "Food & Drink",
        "skin": false
    },
    "🫖": {
        "slug": "teapot",
        "group": "Food & Drink",
        "skin": false
    },
    "🍵": {
        "slug": "teacup_without_handle",
        "group": "Food & Drink",
        "skin": false
    },
    "🍶": {
        "slug": "sake",
        "group": "Food & Drink",
        "skin": false
    },
    "🍾": {
        "slug": "bottle_with_popping_cork",
        "group": "Food & Drink",
        "skin": false
    },
    "🍷": {
        "slug": "wine_glass",
        "group": "Food & Drink",
        "skin": false
    },
    "🍸": {
        "slug": "cocktail_glass",
        "group": "Food & Drink",
        "skin": false
    },
    "🍹": {
        "slug": "tropical_drink",
        "group": "Food & Drink",
        "skin": false
    },
    "🍺": {
        "slug": "beer_mug",
        "group": "Food & Drink",
        "skin": false
    },
    "🍻": {
        "slug": "clinking_beer_mugs",
        "group": "Food & Drink",
        "skin": false
    },
    "🥂": {
        "slug": "clinking_glasses",
        "group": "Food & Drink",
        "skin": false
    },
    "🥃": {
        "slug": "tumbler_glass",
        "group": "Food & Drink",
        "skin": false
    },
    "🫗": {
        "slug": "pouring_liquid",
        "group": "Food & Drink",
        "skin": false
    },
    "🥤": {
        "slug": "cup_with_straw",
        "group": "Food & Drink",
        "skin": false
    },
    "🧋": {
        "slug": "bubble_tea",
        "group": "Food & Drink",
        "skin": false
    },
    "🧃": {
        "slug": "beverage_box",
        "group": "Food & Drink",
        "skin": false
    },
    "🧉": {
        "slug": "mate",
        "group": "Food & Drink",
        "skin": false
    },
    "🧊": {
        "slug": "ice",
        "group": "Food & Drink",
        "skin": false
    },
    "🥢": {
        "slug": "chopsticks",
        "group": "Food & Drink",
        "skin": false
    },
    "🍽️": {
        "slug": "fork_and_knife_with_plate",
        "group": "Food & Drink",
        "skin": false
    },
    "🍴": {
        "slug": "fork_and_knife",
        "group": "Food & Drink",
        "skin": false
    },
    "🥄": {
        "slug": "spoon",
        "group": "Food & Drink",
        "skin": false
    },
    "🔪": {
        "slug": "kitchen_knife",
        "group": "Food & Drink",
        "skin": false
    },
    "🫙": {
        "slug": "jar",
        "group": "Food & Drink",
        "skin": false
    },
    "🏺": {
        "slug": "amphora",
        "group": "Food & Drink",
        "skin": false
    },
    "🌍": {
        "slug": "globe_showing_europe_africa",
        "group": "Travel & Places",
        "skin": false
    },
    "🌎": {
        "slug": "globe_showing_americas",
        "group": "Travel & Places",
        "skin": false
    },
    "🌏": {
        "slug": "globe_showing_asia_australia",
        "group": "Travel & Places",
        "skin": false
    },
    "🌐": {
        "slug": "globe_with_meridians",
        "group": "Travel & Places",
        "skin": false
    },
    "🗺️": {
        "slug": "world_map",
        "group": "Travel & Places",
        "skin": false
    },
    "🗾": {
        "slug": "map_of_japan",
        "group": "Travel & Places",
        "skin": false
    },
    "🧭": {
        "slug": "compass",
        "group": "Travel & Places",
        "skin": false
    },
    "🏔️": {
        "slug": "snow_capped_mountain",
        "group": "Travel & Places",
        "skin": false
    },
    "⛰️": {
        "slug": "mountain",
        "group": "Travel & Places",
        "skin": false
    },
    "🌋": {
        "slug": "volcano",
        "group": "Travel & Places",
        "skin": false
    },
    "🗻": {
        "slug": "mount_fuji",
        "group": "Travel & Places",
        "skin": false
    },
    "🏕️": {
        "slug": "camping",
        "group": "Travel & Places",
        "skin": false
    },
    "🏖️": {
        "slug": "beach_with_umbrella",
        "group": "Travel & Places",
        "skin": false
    },
    "🏜️": {
        "slug": "desert",
        "group": "Travel & Places",
        "skin": false
    },
    "🏝️": {
        "slug": "desert_island",
        "group": "Travel & Places",
        "skin": false
    },
    "🏞️": {
        "slug": "national_park",
        "group": "Travel & Places",
        "skin": false
    },
    "🏟️": {
        "slug": "stadium",
        "group": "Travel & Places",
        "skin": false
    },
    "🏛️": {
        "slug": "classical_building",
        "group": "Travel & Places",
        "skin": false
    },
    "🏗️": {
        "slug": "building_construction",
        "group": "Travel & Places",
        "skin": false
    },
    "🧱": {
        "slug": "brick",
        "group": "Travel & Places",
        "skin": false
    },
    "🪨": {
        "slug": "rock",
        "group": "Travel & Places",
        "skin": false
    },
    "🪵": {
        "slug": "wood",
        "group": "Travel & Places",
        "skin": false
    },
    "🛖": {
        "slug": "hut",
        "group": "Travel & Places",
        "skin": false
    },
    "🏘️": {
        "slug": "houses",
        "group": "Travel & Places",
        "skin": false
    },
    "🏚️": {
        "slug": "derelict_house",
        "group": "Travel & Places",
        "skin": false
    },
    "🏠": {
        "slug": "house",
        "group": "Travel & Places",
        "skin": false
    },
    "🏡": {
        "slug": "house_with_garden",
        "group": "Travel & Places",
        "skin": false
    },
    "🏢": {
        "slug": "office_building",
        "group": "Travel & Places",
        "skin": false
    },
    "🏣": {
        "slug": "japanese_post_office",
        "group": "Travel & Places",
        "skin": false
    },
    "🏤": {
        "slug": "post_office",
        "group": "Travel & Places",
        "skin": false
    },
    "🏥": {
        "slug": "hospital",
        "group": "Travel & Places",
        "skin": false
    },
    "🏦": {
        "slug": "bank",
        "group": "Travel & Places",
        "skin": false
    },
    "🏨": {
        "slug": "hotel",
        "group": "Travel & Places",
        "skin": false
    },
    "🏩": {
        "slug": "love_hotel",
        "group": "Travel & Places",
        "skin": false
    },
    "🏪": {
        "slug": "convenience_store",
        "group": "Travel & Places",
        "skin": false
    },
    "🏫": {
        "slug": "school",
        "group": "Travel & Places",
        "skin": false
    },
    "🏬": {
        "slug": "department_store",
        "group": "Travel & Places",
        "skin": false
    },
    "🏭": {
        "slug": "factory",
        "group": "Travel & Places",
        "skin": false
    },
    "🏯": {
        "slug": "japanese_castle",
        "group": "Travel & Places",
        "skin": false
    },
    "🏰": {
        "slug": "castle",
        "group": "Travel & Places",
        "skin": false
    },
    "💒": {
        "slug": "wedding",
        "group": "Travel & Places",
        "skin": false
    },
    "🗼": {
        "slug": "tokyo_tower",
        "group": "Travel & Places",
        "skin": false
    },
    "🗽": {
        "slug": "statue_of_liberty",
        "group": "Travel & Places",
        "skin": false
    },
    "⛪": {
        "slug": "church",
        "group": "Travel & Places",
        "skin": false
    },
    "🕌": {
        "slug": "mosque",
        "group": "Travel & Places",
        "skin": false
    },
    "🛕": {
        "slug": "hindu_temple",
        "group": "Travel & Places",
        "skin": false
    },
    "🕍": {
        "slug": "synagogue",
        "group": "Travel & Places",
        "skin": false
    },
    "⛩️": {
        "slug": "shinto_shrine",
        "group": "Travel & Places",
        "skin": false
    },
    "🕋": {
        "slug": "kaaba",
        "group": "Travel & Places",
        "skin": false
    },
    "⛲": {
        "slug": "fountain",
        "group": "Travel & Places",
        "skin": false
    },
    "⛺": {
        "slug": "tent",
        "group": "Travel & Places",
        "skin": false
    },
    "🌁": {
        "slug": "foggy",
        "group": "Travel & Places",
        "skin": false
    },
    "🌃": {
        "slug": "night_with_stars",
        "group": "Travel & Places",
        "skin": false
    },
    "🏙️": {
        "slug": "cityscape",
        "group": "Travel & Places",
        "skin": false
    },
    "🌄": {
        "slug": "sunrise_over_mountains",
        "group": "Travel & Places",
        "skin": false
    },
    "🌅": {
        "slug": "sunrise",
        "group": "Travel & Places",
        "skin": false
    },
    "🌆": {
        "slug": "cityscape_at_dusk",
        "group": "Travel & Places",
        "skin": false
    },
    "🌇": {
        "slug": "sunset",
        "group": "Travel & Places",
        "skin": false
    },
    "🌉": {
        "slug": "bridge_at_night",
        "group": "Travel & Places",
        "skin": false
    },
    "♨️": {
        "slug": "hot_springs",
        "group": "Travel & Places",
        "skin": false
    },
    "🎠": {
        "slug": "carousel_horse",
        "group": "Travel & Places",
        "skin": false
    },
    "🛝": {
        "slug": "playground_slide",
        "group": "Travel & Places",
        "skin": false
    },
    "🎡": {
        "slug": "ferris_wheel",
        "group": "Travel & Places",
        "skin": false
    },
    "🎢": {
        "slug": "roller_coaster",
        "group": "Travel & Places",
        "skin": false
    },
    "💈": {
        "slug": "barber_pole",
        "group": "Travel & Places",
        "skin": false
    },
    "🎪": {
        "slug": "circus_tent",
        "group": "Travel & Places",
        "skin": false
    },
    "🚂": {
        "slug": "locomotive",
        "group": "Travel & Places",
        "skin": false
    },
    "🚃": {
        "slug": "railway_car",
        "group": "Travel & Places",
        "skin": false
    },
    "🚄": {
        "slug": "high_speed_train",
        "group": "Travel & Places",
        "skin": false
    },
    "🚅": {
        "slug": "bullet_train",
        "group": "Travel & Places",
        "skin": false
    },
    "🚆": {
        "slug": "train",
        "group": "Travel & Places",
        "skin": false
    },
    "🚇": {
        "slug": "metro",
        "group": "Travel & Places",
        "skin": false
    },
    "🚈": {
        "slug": "light_rail",
        "group": "Travel & Places",
        "skin": false
    },
    "🚉": {
        "slug": "station",
        "group": "Travel & Places",
        "skin": false
    },
    "🚊": {
        "slug": "tram",
        "group": "Travel & Places",
        "skin": false
    },
    "🚝": {
        "slug": "monorail",
        "group": "Travel & Places",
        "skin": false
    },
    "🚞": {
        "slug": "mountain_railway",
        "group": "Travel & Places",
        "skin": false
    },
    "🚋": {
        "slug": "tram_car",
        "group": "Travel & Places",
        "skin": false
    },
    "🚌": {
        "slug": "bus",
        "group": "Travel & Places",
        "skin": false
    },
    "🚍": {
        "slug": "oncoming_bus",
        "group": "Travel & Places",
        "skin": false
    },
    "🚎": {
        "slug": "trolleybus",
        "group": "Travel & Places",
        "skin": false
    },
    "🚐": {
        "slug": "minibus",
        "group": "Travel & Places",
        "skin": false
    },
    "🚑": {
        "slug": "ambulance",
        "group": "Travel & Places",
        "skin": false
    },
    "🚒": {
        "slug": "fire_engine",
        "group": "Travel & Places",
        "skin": false
    },
    "🚓": {
        "slug": "police_car",
        "group": "Travel & Places",
        "skin": false
    },
    "🚔": {
        "slug": "oncoming_police_car",
        "group": "Travel & Places",
        "skin": false
    },
    "🚕": {
        "slug": "taxi",
        "group": "Travel & Places",
        "skin": false
    },
    "🚖": {
        "slug": "oncoming_taxi",
        "group": "Travel & Places",
        "skin": false
    },
    "🚗": {
        "slug": "automobile",
        "group": "Travel & Places",
        "skin": false
    },
    "🚘": {
        "slug": "oncoming_automobile",
        "group": "Travel & Places",
        "skin": false
    },
    "🚙": {
        "slug": "sport_utility_vehicle",
        "group": "Travel & Places",
        "skin": false
    },
    "🛻": {
        "slug": "pickup_truck",
        "group": "Travel & Places",
        "skin": false
    },
    "🚚": {
        "slug": "delivery_truck",
        "group": "Travel & Places",
        "skin": false
    },
    "🚛": {
        "slug": "articulated_lorry",
        "group": "Travel & Places",
        "skin": false
    },
    "🚜": {
        "slug": "tractor",
        "group": "Travel & Places",
        "skin": false
    },
    "🏎️": {
        "slug": "racing_car",
        "group": "Travel & Places",
        "skin": false
    },
    "🏍️": {
        "slug": "motorcycle",
        "group": "Travel & Places",
        "skin": false
    },
    "🛵": {
        "slug": "motor_scooter",
        "group": "Travel & Places",
        "skin": false
    },
    "🦽": {
        "slug": "manual_wheelchair",
        "group": "Travel & Places",
        "skin": false
    },
    "🦼": {
        "slug": "motorized_wheelchair",
        "group": "Travel & Places",
        "skin": false
    },
    "🛺": {
        "slug": "auto_rickshaw",
        "group": "Travel & Places",
        "skin": false
    },
    "🚲": {
        "slug": "bicycle",
        "group": "Travel & Places",
        "skin": false
    },
    "🛴": {
        "slug": "kick_scooter",
        "group": "Travel & Places",
        "skin": false
    },
    "🛹": {
        "slug": "skateboard",
        "group": "Travel & Places",
        "skin": false
    },
    "🛼": {
        "slug": "roller_skate",
        "group": "Travel & Places",
        "skin": false
    },
    "🚏": {
        "slug": "bus_stop",
        "group": "Travel & Places",
        "skin": false
    },
    "🛣️": {
        "slug": "motorway",
        "group": "Travel & Places",
        "skin": false
    },
    "🛤️": {
        "slug": "railway_track",
        "group": "Travel & Places",
        "skin": false
    },
    "🛢️": {
        "slug": "oil_drum",
        "group": "Travel & Places",
        "skin": false
    },
    "⛽": {
        "slug": "fuel_pump",
        "group": "Travel & Places",
        "skin": false
    },
    "🛞": {
        "slug": "wheel",
        "group": "Travel & Places",
        "skin": false
    },
    "🚨": {
        "slug": "police_car_light",
        "group": "Travel & Places",
        "skin": false
    },
    "🚥": {
        "slug": "horizontal_traffic_light",
        "group": "Travel & Places",
        "skin": false
    },
    "🚦": {
        "slug": "vertical_traffic_light",
        "group": "Travel & Places",
        "skin": false
    },
    "🛑": {
        "slug": "stop_sign",
        "group": "Travel & Places",
        "skin": false
    },
    "🚧": {
        "slug": "construction",
        "group": "Travel & Places",
        "skin": false
    },
    "⚓": {
        "slug": "anchor",
        "group": "Travel & Places",
        "skin": false
    },
    "🛟": {
        "slug": "ring_buoy",
        "group": "Travel & Places",
        "skin": false
    },
    "⛵": {
        "slug": "sailboat",
        "group": "Travel & Places",
        "skin": false
    },
    "🛶": {
        "slug": "canoe",
        "group": "Travel & Places",
        "skin": false
    },
    "🚤": {
        "slug": "speedboat",
        "group": "Travel & Places",
        "skin": false
    },
    "🛳️": {
        "slug": "passenger_ship",
        "group": "Travel & Places",
        "skin": false
    },
    "⛴️": {
        "slug": "ferry",
        "group": "Travel & Places",
        "skin": false
    },
    "🛥️": {
        "slug": "motor_boat",
        "group": "Travel & Places",
        "skin": false
    },
    "🚢": {
        "slug": "ship",
        "group": "Travel & Places",
        "skin": false
    },
    "✈️": {
        "slug": "airplane",
        "group": "Travel & Places",
        "skin": false
    },
    "🛩️": {
        "slug": "small_airplane",
        "group": "Travel & Places",
        "skin": false
    },
    "🛫": {
        "slug": "airplane_departure",
        "group": "Travel & Places",
        "skin": false
    },
    "🛬": {
        "slug": "airplane_arrival",
        "group": "Travel & Places",
        "skin": false
    },
    "🪂": {
        "slug": "parachute",
        "group": "Travel & Places",
        "skin": false
    },
    "💺": {
        "slug": "seat",
        "group": "Travel & Places",
        "skin": false
    },
    "🚁": {
        "slug": "helicopter",
        "group": "Travel & Places",
        "skin": false
    },
    "🚟": {
        "slug": "suspension_railway",
        "group": "Travel & Places",
        "skin": false
    },
    "🚠": {
        "slug": "mountain_cableway",
        "group": "Travel & Places",
        "skin": false
    },
    "🚡": {
        "slug": "aerial_tramway",
        "group": "Travel & Places",
        "skin": false
    },
    "🛰️": {
        "slug": "satellite",
        "group": "Travel & Places",
        "skin": false
    },
    "🚀": {
        "slug": "rocket",
        "group": "Travel & Places",
        "skin": false
    },
    "🛸": {
        "slug": "flying_saucer",
        "group": "Travel & Places",
        "skin": false
    },
    "🛎️": {
        "slug": "bellhop_bell",
        "group": "Travel & Places",
        "skin": false
    },
    "🧳": {
        "slug": "luggage",
        "group": "Travel & Places",
        "skin": false
    },
    "⌛": {
        "slug": "hourglass_done",
        "group": "Travel & Places",
        "skin": false
    },
    "⏳": {
        "slug": "hourglass_not_done",
        "group": "Travel & Places",
        "skin": false
    },
    "⌚": {
        "slug": "watch",
        "group": "Travel & Places",
        "skin": false
    },
    "⏰": {
        "slug": "alarm_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "⏱️": {
        "slug": "stopwatch",
        "group": "Travel & Places",
        "skin": false
    },
    "⏲️": {
        "slug": "timer_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕰️": {
        "slug": "mantelpiece_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕛": {
        "slug": "twelve_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕧": {
        "slug": "twelve_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕐": {
        "slug": "one_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕜": {
        "slug": "one_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕑": {
        "slug": "two_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕝": {
        "slug": "two_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕒": {
        "slug": "three_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕞": {
        "slug": "three_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕓": {
        "slug": "four_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕟": {
        "slug": "four_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕔": {
        "slug": "five_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕠": {
        "slug": "five_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕕": {
        "slug": "six_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕡": {
        "slug": "six_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕖": {
        "slug": "seven_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕢": {
        "slug": "seven_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕗": {
        "slug": "eight_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕣": {
        "slug": "eight_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕘": {
        "slug": "nine_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕤": {
        "slug": "nine_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕙": {
        "slug": "ten_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕥": {
        "slug": "ten_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🕚": {
        "slug": "eleven_o_clock",
        "group": "Travel & Places",
        "skin": false
    },
    "🕦": {
        "slug": "eleven_thirty",
        "group": "Travel & Places",
        "skin": false
    },
    "🌑": {
        "slug": "new_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌒": {
        "slug": "waxing_crescent_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌓": {
        "slug": "first_quarter_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌔": {
        "slug": "waxing_gibbous_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌕": {
        "slug": "full_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌖": {
        "slug": "waning_gibbous_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌗": {
        "slug": "last_quarter_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌘": {
        "slug": "waning_crescent_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌙": {
        "slug": "crescent_moon",
        "group": "Travel & Places",
        "skin": false
    },
    "🌚": {
        "slug": "new_moon_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🌛": {
        "slug": "first_quarter_moon_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🌜": {
        "slug": "last_quarter_moon_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🌡️": {
        "slug": "thermometer",
        "group": "Travel & Places",
        "skin": false
    },
    "☀️": {
        "slug": "sun",
        "group": "Travel & Places",
        "skin": false
    },
    "🌝": {
        "slug": "full_moon_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🌞": {
        "slug": "sun_with_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🪐": {
        "slug": "ringed_planet",
        "group": "Travel & Places",
        "skin": false
    },
    "⭐": {
        "slug": "star",
        "group": "Travel & Places",
        "skin": false
    },
    "🌟": {
        "slug": "glowing_star",
        "group": "Travel & Places",
        "skin": false
    },
    "🌠": {
        "slug": "shooting_star",
        "group": "Travel & Places",
        "skin": false
    },
    "🌌": {
        "slug": "milky_way",
        "group": "Travel & Places",
        "skin": false
    },
    "☁️": {
        "slug": "cloud",
        "group": "Travel & Places",
        "skin": false
    },
    "⛅": {
        "slug": "sun_behind_cloud",
        "group": "Travel & Places",
        "skin": false
    },
    "⛈️": {
        "slug": "cloud_with_lightning_and_rain",
        "group": "Travel & Places",
        "skin": false
    },
    "🌤️": {
        "slug": "sun_behind_small_cloud",
        "group": "Travel & Places",
        "skin": false
    },
    "🌥️": {
        "slug": "sun_behind_large_cloud",
        "group": "Travel & Places",
        "skin": false
    },
    "🌦️": {
        "slug": "sun_behind_rain_cloud",
        "group": "Travel & Places",
        "skin": false
    },
    "🌧️": {
        "slug": "cloud_with_rain",
        "group": "Travel & Places",
        "skin": false
    },
    "🌨️": {
        "slug": "cloud_with_snow",
        "group": "Travel & Places",
        "skin": false
    },
    "🌩️": {
        "slug": "cloud_with_lightning",
        "group": "Travel & Places",
        "skin": false
    },
    "🌪️": {
        "slug": "tornado",
        "group": "Travel & Places",
        "skin": false
    },
    "🌫️": {
        "slug": "fog",
        "group": "Travel & Places",
        "skin": false
    },
    "🌬️": {
        "slug": "wind_face",
        "group": "Travel & Places",
        "skin": false
    },
    "🌀": {
        "slug": "cyclone",
        "group": "Travel & Places",
        "skin": false
    },
    "🌈": {
        "slug": "rainbow",
        "group": "Travel & Places",
        "skin": false
    },
    "🌂": {
        "slug": "closed_umbrella",
        "group": "Travel & Places",
        "skin": false
    },
    "☂️": {
        "slug": "umbrella",
        "group": "Travel & Places",
        "skin": false
    },
    "☔": {
        "slug": "umbrella_with_rain_drops",
        "group": "Travel & Places",
        "skin": false
    },
    "⛱️": {
        "slug": "umbrella_on_ground",
        "group": "Travel & Places",
        "skin": false
    },
    "⚡": {
        "slug": "high_voltage",
        "group": "Travel & Places",
        "skin": false
    },
    "❄️": {
        "slug": "snowflake",
        "group": "Travel & Places",
        "skin": false
    },
    "☃️": {
        "slug": "snowman",
        "group": "Travel & Places",
        "skin": false
    },
    "⛄": {
        "slug": "snowman_without_snow",
        "group": "Travel & Places",
        "skin": false
    },
    "☄️": {
        "slug": "comet",
        "group": "Travel & Places",
        "skin": false
    },
    "🔥": {
        "slug": "fire",
        "group": "Travel & Places",
        "skin": false
    },
    "💧": {
        "slug": "droplet",
        "group": "Travel & Places",
        "skin": false
    },
    "🌊": {
        "slug": "water_wave",
        "group": "Travel & Places",
        "skin": false
    },
    "🎃": {
        "slug": "jack_o_lantern",
        "group": "Activities",
        "skin": false
    },
    "🎄": {
        "slug": "christmas_tree",
        "group": "Activities",
        "skin": false
    },
    "🎆": {
        "slug": "fireworks",
        "group": "Activities",
        "skin": false
    },
    "🎇": {
        "slug": "sparkler",
        "group": "Activities",
        "skin": false
    },
    "🧨": {
        "slug": "firecracker",
        "group": "Activities",
        "skin": false
    },
    "✨": {
        "slug": "sparkles",
        "group": "Activities",
        "skin": false
    },
    "🎈": {
        "slug": "balloon",
        "group": "Activities",
        "skin": false
    },
    "🎉": {
        "slug": "party_popper",
        "group": "Activities",
        "skin": false
    },
    "🎊": {
        "slug": "confetti_ball",
        "group": "Activities",
        "skin": false
    },
    "🎋": {
        "slug": "tanabata_tree",
        "group": "Activities",
        "skin": false
    },
    "🎍": {
        "slug": "pine_decoration",
        "group": "Activities",
        "skin": false
    },
    "🎎": {
        "slug": "japanese_dolls",
        "group": "Activities",
        "skin": false
    },
    "🎏": {
        "slug": "carp_streamer",
        "group": "Activities",
        "skin": false
    },
    "🎐": {
        "slug": "wind_chime",
        "group": "Activities",
        "skin": false
    },
    "🎑": {
        "slug": "moon_viewing_ceremony",
        "group": "Activities",
        "skin": false
    },
    "🧧": {
        "slug": "red_envelope",
        "group": "Activities",
        "skin": false
    },
    "🎀": {
        "slug": "ribbon",
        "group": "Activities",
        "skin": false
    },
    "🎁": {
        "slug": "wrapped_gift",
        "group": "Activities",
        "skin": false
    },
    "🎗️": {
        "slug": "reminder_ribbon",
        "group": "Activities",
        "skin": false
    },
    "🎟️": {
        "slug": "admission_tickets",
        "group": "Activities",
        "skin": false
    },
    "🎫": {
        "slug": "ticket",
        "group": "Activities",
        "skin": false
    },
    "🎖️": {
        "slug": "military_medal",
        "group": "Activities",
        "skin": false
    },
    "🏆": {
        "slug": "trophy",
        "group": "Activities",
        "skin": false
    },
    "🏅": {
        "slug": "sports_medal",
        "group": "Activities",
        "skin": false
    },
    "🥇": {
        "slug": "1st_place_medal",
        "group": "Activities",
        "skin": false
    },
    "🥈": {
        "slug": "2nd_place_medal",
        "group": "Activities",
        "skin": false
    },
    "🥉": {
        "slug": "3rd_place_medal",
        "group": "Activities",
        "skin": false
    },
    "⚽": {
        "slug": "soccer_ball",
        "group": "Activities",
        "skin": false
    },
    "⚾": {
        "slug": "baseball",
        "group": "Activities",
        "skin": false
    },
    "🥎": {
        "slug": "softball",
        "group": "Activities",
        "skin": false
    },
    "🏀": {
        "slug": "basketball",
        "group": "Activities",
        "skin": false
    },
    "🏐": {
        "slug": "volleyball",
        "group": "Activities",
        "skin": false
    },
    "🏈": {
        "slug": "american_football",
        "group": "Activities",
        "skin": false
    },
    "🏉": {
        "slug": "rugby_football",
        "group": "Activities",
        "skin": false
    },
    "🎾": {
        "slug": "tennis",
        "group": "Activities",
        "skin": false
    },
    "🥏": {
        "slug": "flying_disc",
        "group": "Activities",
        "skin": false
    },
    "🎳": {
        "slug": "bowling",
        "group": "Activities",
        "skin": false
    },
    "🏏": {
        "slug": "cricket_game",
        "group": "Activities",
        "skin": false
    },
    "🏑": {
        "slug": "field_hockey",
        "group": "Activities",
        "skin": false
    },
    "🏒": {
        "slug": "ice_hockey",
        "group": "Activities",
        "skin": false
    },
    "🥍": {
        "slug": "lacrosse",
        "group": "Activities",
        "skin": false
    },
    "🏓": {
        "slug": "ping_pong",
        "group": "Activities",
        "skin": false
    },
    "🏸": {
        "slug": "badminton",
        "group": "Activities",
        "skin": false
    },
    "🥊": {
        "slug": "boxing_glove",
        "group": "Activities",
        "skin": false
    },
    "🥋": {
        "slug": "martial_arts_uniform",
        "group": "Activities",
        "skin": false
    },
    "🥅": {
        "slug": "goal_net",
        "group": "Activities",
        "skin": false
    },
    "⛳": {
        "slug": "flag_in_hole",
        "group": "Activities",
        "skin": false
    },
    "⛸️": {
        "slug": "ice_skate",
        "group": "Activities",
        "skin": false
    },
    "🎣": {
        "slug": "fishing_pole",
        "group": "Activities",
        "skin": false
    },
    "🤿": {
        "slug": "diving_mask",
        "group": "Activities",
        "skin": false
    },
    "🎽": {
        "slug": "running_shirt",
        "group": "Activities",
        "skin": false
    },
    "🎿": {
        "slug": "skis",
        "group": "Activities",
        "skin": false
    },
    "🛷": {
        "slug": "sled",
        "group": "Activities",
        "skin": false
    },
    "🥌": {
        "slug": "curling_stone",
        "group": "Activities",
        "skin": false
    },
    "🎯": {
        "slug": "bullseye",
        "group": "Activities",
        "skin": false
    },
    "🪀": {
        "slug": "yo_yo",
        "group": "Activities",
        "skin": false
    },
    "🪁": {
        "slug": "kite",
        "group": "Activities",
        "skin": false
    },
    "🔫": {
        "slug": "water_pistol",
        "group": "Activities",
        "skin": false
    },
    "🎱": {
        "slug": "pool_8_ball",
        "group": "Activities",
        "skin": false
    },
    "🔮": {
        "slug": "crystal_ball",
        "group": "Activities",
        "skin": false
    },
    "🪄": {
        "slug": "magic_wand",
        "group": "Activities",
        "skin": false
    },
    "🎮": {
        "slug": "video_game",
        "group": "Activities",
        "skin": false
    },
    "🕹️": {
        "slug": "joystick",
        "group": "Activities",
        "skin": false
    },
    "🎰": {
        "slug": "slot_machine",
        "group": "Activities",
        "skin": false
    },
    "🎲": {
        "slug": "game_die",
        "group": "Activities",
        "skin": false
    },
    "🧩": {
        "slug": "puzzle_piece",
        "group": "Activities",
        "skin": false
    },
    "🧸": {
        "slug": "teddy_bear",
        "group": "Activities",
        "skin": false
    },
    "🪅": {
        "slug": "pinata",
        "group": "Activities",
        "skin": false
    },
    "🪩": {
        "slug": "mirror_ball",
        "group": "Activities",
        "skin": false
    },
    "🪆": {
        "slug": "nesting_dolls",
        "group": "Activities",
        "skin": false
    },
    "♠️": {
        "slug": "spade_suit",
        "group": "Activities",
        "skin": false
    },
    "♥️": {
        "slug": "heart_suit",
        "group": "Activities",
        "skin": false
    },
    "♦️": {
        "slug": "diamond_suit",
        "group": "Activities",
        "skin": false
    },
    "♣️": {
        "slug": "club_suit",
        "group": "Activities",
        "skin": false
    },
    "♟️": {
        "slug": "chess_pawn",
        "group": "Activities",
        "skin": false
    },
    "🃏": {
        "slug": "joker",
        "group": "Activities",
        "skin": false
    },
    "🀄": {
        "slug": "mahjong_red_dragon",
        "group": "Activities",
        "skin": false
    },
    "🎴": {
        "slug": "flower_playing_cards",
        "group": "Activities",
        "skin": false
    },
    "🎭": {
        "slug": "performing_arts",
        "group": "Activities",
        "skin": false
    },
    "🖼️": {
        "slug": "framed_picture",
        "group": "Activities",
        "skin": false
    },
    "🎨": {
        "slug": "artist_palette",
        "group": "Activities",
        "skin": false
    },
    "🧵": {
        "slug": "thread",
        "group": "Activities",
        "skin": false
    },
    "🪡": {
        "slug": "sewing_needle",
        "group": "Activities",
        "skin": false
    },
    "🧶": {
        "slug": "yarn",
        "group": "Activities",
        "skin": false
    },
    "🪢": {
        "slug": "knot",
        "group": "Activities",
        "skin": false
    },
    "👓": {
        "slug": "glasses",
        "group": "Objects",
        "skin": false
    },
    "🕶️": {
        "slug": "sunglasses",
        "group": "Objects",
        "skin": false
    },
    "🥽": {
        "slug": "goggles",
        "group": "Objects",
        "skin": false
    },
    "🥼": {
        "slug": "lab_coat",
        "group": "Objects",
        "skin": false
    },
    "🦺": {
        "slug": "safety_vest",
        "group": "Objects",
        "skin": false
    },
    "👔": {
        "slug": "necktie",
        "group": "Objects",
        "skin": false
    },
    "👕": {
        "slug": "t_shirt",
        "group": "Objects",
        "skin": false
    },
    "👖": {
        "slug": "jeans",
        "group": "Objects",
        "skin": false
    },
    "🧣": {
        "slug": "scarf",
        "group": "Objects",
        "skin": false
    },
    "🧤": {
        "slug": "gloves",
        "group": "Objects",
        "skin": false
    },
    "🧥": {
        "slug": "coat",
        "group": "Objects",
        "skin": false
    },
    "🧦": {
        "slug": "socks",
        "group": "Objects",
        "skin": false
    },
    "👗": {
        "slug": "dress",
        "group": "Objects",
        "skin": false
    },
    "👘": {
        "slug": "kimono",
        "group": "Objects",
        "skin": false
    },
    "🥻": {
        "slug": "sari",
        "group": "Objects",
        "skin": false
    },
    "🩱": {
        "slug": "one_piece_swimsuit",
        "group": "Objects",
        "skin": false
    },
    "🩲": {
        "slug": "briefs",
        "group": "Objects",
        "skin": false
    },
    "🩳": {
        "slug": "shorts",
        "group": "Objects",
        "skin": false
    },
    "👙": {
        "slug": "bikini",
        "group": "Objects",
        "skin": false
    },
    "👚": {
        "slug": "woman_s_clothes",
        "group": "Objects",
        "skin": false
    },
    "🪭": {
        "slug": "folding_hand_fan",
        "group": "Objects",
        "skin": false
    },
    "👛": {
        "slug": "purse",
        "group": "Objects",
        "skin": false
    },
    "👜": {
        "slug": "handbag",
        "group": "Objects",
        "skin": false
    },
    "👝": {
        "slug": "clutch_bag",
        "group": "Objects",
        "skin": false
    },
    "🛍️": {
        "slug": "shopping_bags",
        "group": "Objects",
        "skin": false
    },
    "🎒": {
        "slug": "backpack",
        "group": "Objects",
        "skin": false
    },
    "🩴": {
        "slug": "thong_sandal",
        "group": "Objects",
        "skin": false
    },
    "👞": {
        "slug": "man_s_shoe",
        "group": "Objects",
        "skin": false
    },
    "👟": {
        "slug": "running_shoe",
        "group": "Objects",
        "skin": false
    },
    "🥾": {
        "slug": "hiking_boot",
        "group": "Objects",
        "skin": false
    },
    "🥿": {
        "slug": "flat_shoe",
        "group": "Objects",
        "skin": false
    },
    "👠": {
        "slug": "high_heeled_shoe",
        "group": "Objects",
        "skin": false
    },
    "👡": {
        "slug": "woman_s_sandal",
        "group": "Objects",
        "skin": false
    },
    "🩰": {
        "slug": "ballet_shoes",
        "group": "Objects",
        "skin": false
    },
    "👢": {
        "slug": "woman_s_boot",
        "group": "Objects",
        "skin": false
    },
    "🪮": {
        "slug": "hair_pick",
        "group": "Objects",
        "skin": false
    },
    "👑": {
        "slug": "crown",
        "group": "Objects",
        "skin": false
    },
    "👒": {
        "slug": "woman_s_hat",
        "group": "Objects",
        "skin": false
    },
    "🎩": {
        "slug": "top_hat",
        "group": "Objects",
        "skin": false
    },
    "🎓": {
        "slug": "graduation_cap",
        "group": "Objects",
        "skin": false
    },
    "🧢": {
        "slug": "billed_cap",
        "group": "Objects",
        "skin": false
    },
    "🪖": {
        "slug": "military_helmet",
        "group": "Objects",
        "skin": false
    },
    "⛑️": {
        "slug": "rescue_worker_s_helmet",
        "group": "Objects",
        "skin": false
    },
    "📿": {
        "slug": "prayer_beads",
        "group": "Objects",
        "skin": false
    },
    "💄": {
        "slug": "lipstick",
        "group": "Objects",
        "skin": false
    },
    "💍": {
        "slug": "ring",
        "group": "Objects",
        "skin": false
    },
    "💎": {
        "slug": "gem_stone",
        "group": "Objects",
        "skin": false
    },
    "🔇": {
        "slug": "muted_speaker",
        "group": "Objects",
        "skin": false
    },
    "🔈": {
        "slug": "speaker_low_volume",
        "group": "Objects",
        "skin": false
    },
    "🔉": {
        "slug": "speaker_medium_volume",
        "group": "Objects",
        "skin": false
    },
    "🔊": {
        "slug": "speaker_high_volume",
        "group": "Objects",
        "skin": false
    },
    "📢": {
        "slug": "loudspeaker",
        "group": "Objects",
        "skin": false
    },
    "📣": {
        "slug": "megaphone",
        "group": "Objects",
        "skin": false
    },
    "📯": {
        "slug": "postal_horn",
        "group": "Objects",
        "skin": false
    },
    "🔔": {
        "slug": "bell",
        "group": "Objects",
        "skin": false
    },
    "🔕": {
        "slug": "bell_with_slash",
        "group": "Objects",
        "skin": false
    },
    "🎼": {
        "slug": "musical_score",
        "group": "Objects",
        "skin": false
    },
    "🎵": {
        "slug": "musical_note",
        "group": "Objects",
        "skin": false
    },
    "🎶": {
        "slug": "musical_notes",
        "group": "Objects",
        "skin": false
    },
    "🎙️": {
        "slug": "studio_microphone",
        "group": "Objects",
        "skin": false
    },
    "🎚️": {
        "slug": "level_slider",
        "group": "Objects",
        "skin": false
    },
    "🎛️": {
        "slug": "control_knobs",
        "group": "Objects",
        "skin": false
    },
    "🎤": {
        "slug": "microphone",
        "group": "Objects",
        "skin": false
    },
    "🎧": {
        "slug": "headphone",
        "group": "Objects",
        "skin": false
    },
    "📻": {
        "slug": "radio",
        "group": "Objects",
        "skin": false
    },
    "🎷": {
        "slug": "saxophone",
        "group": "Objects",
        "skin": false
    },
    "🪗": {
        "slug": "accordion",
        "group": "Objects",
        "skin": false
    },
    "🎸": {
        "slug": "guitar",
        "group": "Objects",
        "skin": false
    },
    "🎹": {
        "slug": "musical_keyboard",
        "group": "Objects",
        "skin": false
    },
    "🎺": {
        "slug": "trumpet",
        "group": "Objects",
        "skin": false
    },
    "🎻": {
        "slug": "violin",
        "group": "Objects",
        "skin": false
    },
    "🪕": {
        "slug": "banjo",
        "group": "Objects",
        "skin": false
    },
    "🥁": {
        "slug": "drum",
        "group": "Objects",
        "skin": false
    },
    "🪘": {
        "slug": "long_drum",
        "group": "Objects",
        "skin": false
    },
    "🪇": {
        "slug": "maracas",
        "group": "Objects",
        "skin": false
    },
    "🪈": {
        "slug": "flute",
        "group": "Objects",
        "skin": false
    },
    "📱": {
        "slug": "mobile_phone",
        "group": "Objects",
        "skin": false
    },
    "📲": {
        "slug": "mobile_phone_with_arrow",
        "group": "Objects",
        "skin": false
    },
    "☎️": {
        "slug": "telephone",
        "group": "Objects",
        "skin": false
    },
    "📞": {
        "slug": "telephone_receiver",
        "group": "Objects",
        "skin": false
    },
    "📟": {
        "slug": "pager",
        "group": "Objects",
        "skin": false
    },
    "📠": {
        "slug": "fax_machine",
        "group": "Objects",
        "skin": false
    },
    "🔋": {
        "slug": "battery",
        "group": "Objects",
        "skin": false
    },
    "🪫": {
        "slug": "low_battery",
        "group": "Objects",
        "skin": false
    },
    "🔌": {
        "slug": "electric_plug",
        "group": "Objects",
        "skin": false
    },
    "💻": {
        "slug": "laptop",
        "group": "Objects",
        "skin": false
    },
    "🖥️": {
        "slug": "desktop_computer",
        "group": "Objects",
        "skin": false
    },
    "🖨️": {
        "slug": "printer",
        "group": "Objects",
        "skin": false
    },
    "⌨️": {
        "slug": "keyboard",
        "group": "Objects",
        "skin": false
    },
    "🖱️": {
        "slug": "computer_mouse",
        "group": "Objects",
        "skin": false
    },
    "🖲️": {
        "slug": "trackball",
        "group": "Objects",
        "skin": false
    },
    "💽": {
        "slug": "computer_disk",
        "group": "Objects",
        "skin": false
    },
    "💾": {
        "slug": "floppy_disk",
        "group": "Objects",
        "skin": false
    },
    "💿": {
        "slug": "optical_disk",
        "group": "Objects",
        "skin": false
    },
    "📀": {
        "slug": "dvd",
        "group": "Objects",
        "skin": false
    },
    "🧮": {
        "slug": "abacus",
        "group": "Objects",
        "skin": false
    },
    "🎥": {
        "slug": "movie_camera",
        "group": "Objects",
        "skin": false
    },
    "🎞️": {
        "slug": "film_frames",
        "group": "Objects",
        "skin": false
    },
    "📽️": {
        "slug": "film_projector",
        "group": "Objects",
        "skin": false
    },
    "🎬": {
        "slug": "clapper_board",
        "group": "Objects",
        "skin": false
    },
    "📺": {
        "slug": "television",
        "group": "Objects",
        "skin": false
    },
    "📷": {
        "slug": "camera",
        "group": "Objects",
        "skin": false
    },
    "📸": {
        "slug": "camera_with_flash",
        "group": "Objects",
        "skin": false
    },
    "📹": {
        "slug": "video_camera",
        "group": "Objects",
        "skin": false
    },
    "📼": {
        "slug": "videocassette",
        "group": "Objects",
        "skin": false
    },
    "🔍": {
        "slug": "magnifying_glass_tilted_left",
        "group": "Objects",
        "skin": false
    },
    "🔎": {
        "slug": "magnifying_glass_tilted_right",
        "group": "Objects",
        "skin": false
    },
    "🕯️": {
        "slug": "candle",
        "group": "Objects",
        "skin": false
    },
    "💡": {
        "slug": "light_bulb",
        "group": "Objects",
        "skin": false
    },
    "🔦": {
        "slug": "flashlight",
        "group": "Objects",
        "skin": false
    },
    "🏮": {
        "slug": "red_paper_lantern",
        "group": "Objects",
        "skin": false
    },
    "🪔": {
        "slug": "diya_lamp",
        "group": "Objects",
        "skin": false
    },
    "📔": {
        "slug": "notebook_with_decorative_cover",
        "group": "Objects",
        "skin": false
    },
    "📕": {
        "slug": "closed_book",
        "group": "Objects",
        "skin": false
    },
    "📖": {
        "slug": "open_book",
        "group": "Objects",
        "skin": false
    },
    "📗": {
        "slug": "green_book",
        "group": "Objects",
        "skin": false
    },
    "📘": {
        "slug": "blue_book",
        "group": "Objects",
        "skin": false
    },
    "📙": {
        "slug": "orange_book",
        "group": "Objects",
        "skin": false
    },
    "📚": {
        "slug": "books",
        "group": "Objects",
        "skin": false
    },
    "📓": {
        "slug": "notebook",
        "group": "Objects",
        "skin": false
    },
    "📒": {
        "slug": "ledger",
        "group": "Objects",
        "skin": false
    },
    "📃": {
        "slug": "page_with_curl",
        "group": "Objects",
        "skin": false
    },
    "📜": {
        "slug": "scroll",
        "group": "Objects",
        "skin": false
    },
    "📄": {
        "slug": "page_facing_up",
        "group": "Objects",
        "skin": false
    },
    "📰": {
        "slug": "newspaper",
        "group": "Objects",
        "skin": false
    },
    "🗞️": {
        "slug": "rolled_up_newspaper",
        "group": "Objects",
        "skin": false
    },
    "📑": {
        "slug": "bookmark_tabs",
        "group": "Objects",
        "skin": false
    },
    "🔖": {
        "slug": "bookmark",
        "group": "Objects",
        "skin": false
    },
    "🏷️": {
        "slug": "label",
        "group": "Objects",
        "skin": false
    },
    "💰": {
        "slug": "money_bag",
        "group": "Objects",
        "skin": false
    },
    "🪙": {
        "slug": "coin",
        "group": "Objects",
        "skin": false
    },
    "💴": {
        "slug": "yen_banknote",
        "group": "Objects",
        "skin": false
    },
    "💵": {
        "slug": "dollar_banknote",
        "group": "Objects",
        "skin": false
    },
    "💶": {
        "slug": "euro_banknote",
        "group": "Objects",
        "skin": false
    },
    "💷": {
        "slug": "pound_banknote",
        "group": "Objects",
        "skin": false
    },
    "💸": {
        "slug": "money_with_wings",
        "group": "Objects",
        "skin": false
    },
    "💳": {
        "slug": "credit_card",
        "group": "Objects",
        "skin": false
    },
    "🧾": {
        "slug": "receipt",
        "group": "Objects",
        "skin": false
    },
    "💹": {
        "slug": "chart_increasing_with_yen",
        "group": "Objects",
        "skin": false
    },
    "✉️": {
        "slug": "envelope",
        "group": "Objects",
        "skin": false
    },
    "📧": {
        "slug": "e_mail",
        "group": "Objects",
        "skin": false
    },
    "📨": {
        "slug": "incoming_envelope",
        "group": "Objects",
        "skin": false
    },
    "📩": {
        "slug": "envelope_with_arrow",
        "group": "Objects",
        "skin": false
    },
    "📤": {
        "slug": "outbox_tray",
        "group": "Objects",
        "skin": false
    },
    "📥": {
        "slug": "inbox_tray",
        "group": "Objects",
        "skin": false
    },
    "📦": {
        "slug": "package",
        "group": "Objects",
        "skin": false
    },
    "📫": {
        "slug": "closed_mailbox_with_raised_flag",
        "group": "Objects",
        "skin": false
    },
    "📪": {
        "slug": "closed_mailbox_with_lowered_flag",
        "group": "Objects",
        "skin": false
    },
    "📬": {
        "slug": "open_mailbox_with_raised_flag",
        "group": "Objects",
        "skin": false
    },
    "📭": {
        "slug": "open_mailbox_with_lowered_flag",
        "group": "Objects",
        "skin": false
    },
    "📮": {
        "slug": "postbox",
        "group": "Objects",
        "skin": false
    },
    "🗳️": {
        "slug": "ballot_box_with_ballot",
        "group": "Objects",
        "skin": false
    },
    "✏️": {
        "slug": "pencil",
        "group": "Objects",
        "skin": false
    },
    "✒️": {
        "slug": "black_nib",
        "group": "Objects",
        "skin": false
    },
    "🖋️": {
        "slug": "fountain_pen",
        "group": "Objects",
        "skin": false
    },
    "🖊️": {
        "slug": "pen",
        "group": "Objects",
        "skin": false
    },
    "🖌️": {
        "slug": "paintbrush",
        "group": "Objects",
        "skin": false
    },
    "🖍️": {
        "slug": "crayon",
        "group": "Objects",
        "skin": false
    },
    "📝": {
        "slug": "memo",
        "group": "Objects",
        "skin": false
    },
    "💼": {
        "slug": "briefcase",
        "group": "Objects",
        "skin": false
    },
    "📁": {
        "slug": "file_folder",
        "group": "Objects",
        "skin": false
    },
    "📂": {
        "slug": "open_file_folder",
        "group": "Objects",
        "skin": false
    },
    "🗂️": {
        "slug": "card_index_dividers",
        "group": "Objects",
        "skin": false
    },
    "📅": {
        "slug": "calendar",
        "group": "Objects",
        "skin": false
    },
    "📆": {
        "slug": "tear_off_calendar",
        "group": "Objects",
        "skin": false
    },
    "🗒️": {
        "slug": "spiral_notepad",
        "group": "Objects",
        "skin": false
    },
    "🗓️": {
        "slug": "spiral_calendar",
        "group": "Objects",
        "skin": false
    },
    "📇": {
        "slug": "card_index",
        "group": "Objects",
        "skin": false
    },
    "📈": {
        "slug": "chart_increasing",
        "group": "Objects",
        "skin": false
    },
    "📉": {
        "slug": "chart_decreasing",
        "group": "Objects",
        "skin": false
    },
    "📊": {
        "slug": "bar_chart",
        "group": "Objects",
        "skin": false
    },
    "📋": {
        "slug": "clipboard",
        "group": "Objects",
        "skin": false
    },
    "📌": {
        "slug": "pushpin",
        "group": "Objects",
        "skin": false
    },
    "📍": {
        "slug": "round_pushpin",
        "group": "Objects",
        "skin": false
    },
    "📎": {
        "slug": "paperclip",
        "group": "Objects",
        "skin": false
    },
    "🖇️": {
        "slug": "linked_paperclips",
        "group": "Objects",
        "skin": false
    },
    "📏": {
        "slug": "straight_ruler",
        "group": "Objects",
        "skin": false
    },
    "📐": {
        "slug": "triangular_ruler",
        "group": "Objects",
        "skin": false
    },
    "✂️": {
        "slug": "scissors",
        "group": "Objects",
        "skin": false
    },
    "🗃️": {
        "slug": "card_file_box",
        "group": "Objects",
        "skin": false
    },
    "🗄️": {
        "slug": "file_cabinet",
        "group": "Objects",
        "skin": false
    },
    "🗑️": {
        "slug": "wastebasket",
        "group": "Objects",
        "skin": false
    },
    "🔒": {
        "slug": "locked",
        "group": "Objects",
        "skin": false
    },
    "🔓": {
        "slug": "unlocked",
        "group": "Objects",
        "skin": false
    },
    "🔏": {
        "slug": "locked_with_pen",
        "group": "Objects",
        "skin": false
    },
    "🔐": {
        "slug": "locked_with_key",
        "group": "Objects",
        "skin": false
    },
    "🔑": {
        "slug": "key",
        "group": "Objects",
        "skin": false
    },
    "🗝️": {
        "slug": "old_key",
        "group": "Objects",
        "skin": false
    },
    "🔨": {
        "slug": "hammer",
        "group": "Objects",
        "skin": false
    },
    "🪓": {
        "slug": "axe",
        "group": "Objects",
        "skin": false
    },
    "⛏️": {
        "slug": "pick",
        "group": "Objects",
        "skin": false
    },
    "⚒️": {
        "slug": "hammer_and_pick",
        "group": "Objects",
        "skin": false
    },
    "🛠️": {
        "slug": "hammer_and_wrench",
        "group": "Objects",
        "skin": false
    },
    "🗡️": {
        "slug": "dagger",
        "group": "Objects",
        "skin": false
    },
    "⚔️": {
        "slug": "crossed_swords",
        "group": "Objects",
        "skin": false
    },
    "💣": {
        "slug": "bomb",
        "group": "Objects",
        "skin": false
    },
    "🪃": {
        "slug": "boomerang",
        "group": "Objects",
        "skin": false
    },
    "🏹": {
        "slug": "bow_and_arrow",
        "group": "Objects",
        "skin": false
    },
    "🛡️": {
        "slug": "shield",
        "group": "Objects",
        "skin": false
    },
    "🪚": {
        "slug": "carpentry_saw",
        "group": "Objects",
        "skin": false
    },
    "🔧": {
        "slug": "wrench",
        "group": "Objects",
        "skin": false
    },
    "🪛": {
        "slug": "screwdriver",
        "group": "Objects",
        "skin": false
    },
    "🔩": {
        "slug": "nut_and_bolt",
        "group": "Objects",
        "skin": false
    },
    "⚙️": {
        "slug": "gear",
        "group": "Objects",
        "skin": false
    },
    "🗜️": {
        "slug": "clamp",
        "group": "Objects",
        "skin": false
    },
    "⚖️": {
        "slug": "balance_scale",
        "group": "Objects",
        "skin": false
    },
    "🦯": {
        "slug": "white_cane",
        "group": "Objects",
        "skin": false
    },
    "🔗": {
        "slug": "link",
        "group": "Objects",
        "skin": false
    },
    "⛓️": {
        "slug": "chains",
        "group": "Objects",
        "skin": false
    },
    "🪝": {
        "slug": "hook",
        "group": "Objects",
        "skin": false
    },
    "🧰": {
        "slug": "toolbox",
        "group": "Objects",
        "skin": false
    },
    "🧲": {
        "slug": "magnet",
        "group": "Objects",
        "skin": false
    },
    "🪜": {
        "slug": "ladder",
        "group": "Objects",
        "skin": false
    },
    "⚗️": {
        "slug": "alembic",
        "group": "Objects",
        "skin": false
    },
    "🧪": {
        "slug": "test_tube",
        "group": "Objects",
        "skin": false
    },
    "🧫": {
        "slug": "petri_dish",
        "group": "Objects",
        "skin": false
    },
    "🧬": {
        "slug": "dna",
        "group": "Objects",
        "skin": false
    },
    "🔬": {
        "slug": "microscope",
        "group": "Objects",
        "skin": false
    },
    "🔭": {
        "slug": "telescope",
        "group": "Objects",
        "skin": false
    },
    "📡": {
        "slug": "satellite_antenna",
        "group": "Objects",
        "skin": false
    },
    "💉": {
        "slug": "syringe",
        "group": "Objects",
        "skin": false
    },
    "🩸": {
        "slug": "drop_of_blood",
        "group": "Objects",
        "skin": false
    },
    "💊": {
        "slug": "pill",
        "group": "Objects",
        "skin": false
    },
    "🩹": {
        "slug": "adhesive_bandage",
        "group": "Objects",
        "skin": false
    },
    "🩼": {
        "slug": "crutch",
        "group": "Objects",
        "skin": false
    },
    "🩺": {
        "slug": "stethoscope",
        "group": "Objects",
        "skin": false
    },
    "🩻": {
        "slug": "x_ray",
        "group": "Objects",
        "skin": false
    },
    "🚪": {
        "slug": "door",
        "group": "Objects",
        "skin": false
    },
    "🛗": {
        "slug": "elevator",
        "group": "Objects",
        "skin": false
    },
    "🪞": {
        "slug": "mirror",
        "group": "Objects",
        "skin": false
    },
    "🪟": {
        "slug": "window",
        "group": "Objects",
        "skin": false
    },
    "🛏️": {
        "slug": "bed",
        "group": "Objects",
        "skin": false
    },
    "🛋️": {
        "slug": "couch_and_lamp",
        "group": "Objects",
        "skin": false
    },
    "🪑": {
        "slug": "chair",
        "group": "Objects",
        "skin": false
    },
    "🚽": {
        "slug": "toilet",
        "group": "Objects",
        "skin": false
    },
    "🪠": {
        "slug": "plunger",
        "group": "Objects",
        "skin": false
    },
    "🚿": {
        "slug": "shower",
        "group": "Objects",
        "skin": false
    },
    "🛁": {
        "slug": "bathtub",
        "group": "Objects",
        "skin": false
    },
    "🪤": {
        "slug": "mouse_trap",
        "group": "Objects",
        "skin": false
    },
    "🪒": {
        "slug": "razor",
        "group": "Objects",
        "skin": false
    },
    "🧴": {
        "slug": "lotion_bottle",
        "group": "Objects",
        "skin": false
    },
    "🧷": {
        "slug": "safety_pin",
        "group": "Objects",
        "skin": false
    },
    "🧹": {
        "slug": "broom",
        "group": "Objects",
        "skin": false
    },
    "🧺": {
        "slug": "basket",
        "group": "Objects",
        "skin": false
    },
    "🧻": {
        "slug": "roll_of_paper",
        "group": "Objects",
        "skin": false
    },
    "🪣": {
        "slug": "bucket",
        "group": "Objects",
        "skin": false
    },
    "🧼": {
        "slug": "soap",
        "group": "Objects",
        "skin": false
    },
    "🫧": {
        "slug": "bubbles",
        "group": "Objects",
        "skin": false
    },
    "🪥": {
        "slug": "toothbrush",
        "group": "Objects",
        "skin": false
    },
    "🧽": {
        "slug": "sponge",
        "group": "Objects",
        "skin": false
    },
    "🧯": {
        "slug": "fire_extinguisher",
        "group": "Objects",
        "skin": false
    },
    "🛒": {
        "slug": "shopping_cart",
        "group": "Objects",
        "skin": false
    },
    "🚬": {
        "slug": "cigarette",
        "group": "Objects",
        "skin": false
    },
    "⚰️": {
        "slug": "coffin",
        "group": "Objects",
        "skin": false
    },
    "🪦": {
        "slug": "headstone",
        "group": "Objects",
        "skin": false
    },
    "⚱️": {
        "slug": "funeral_urn",
        "group": "Objects",
        "skin": false
    },
    "🧿": {
        "slug": "nazar_amulet",
        "group": "Objects",
        "skin": false
    },
    "🪬": {
        "slug": "hamsa",
        "group": "Objects",
        "skin": false
    },
    "🗿": {
        "slug": "moai",
        "group": "Objects",
        "skin": false
    },
    "🪧": {
        "slug": "placard",
        "group": "Objects",
        "skin": false
    },
    "🪪": {
        "slug": "identification_card",
        "group": "Objects",
        "skin": false
    },
    "🏧": {
        "slug": "atm_sign",
        "group": "Symbols",
        "skin": false
    },
    "🚮": {
        "slug": "litter_in_bin_sign",
        "group": "Symbols",
        "skin": false
    },
    "🚰": {
        "slug": "potable_water",
        "group": "Symbols",
        "skin": false
    },
    "♿": {
        "slug": "wheelchair_symbol",
        "group": "Symbols",
        "skin": false
    },
    "🚹": {
        "slug": "men_s_room",
        "group": "Symbols",
        "skin": false
    },
    "🚺": {
        "slug": "women_s_room",
        "group": "Symbols",
        "skin": false
    },
    "🚻": {
        "slug": "restroom",
        "group": "Symbols",
        "skin": false
    },
    "🚼": {
        "slug": "baby_symbol",
        "group": "Symbols",
        "skin": false
    },
    "🚾": {
        "slug": "water_closet",
        "group": "Symbols",
        "skin": false
    },
    "🛂": {
        "slug": "passport_control",
        "group": "Symbols",
        "skin": false
    },
    "🛃": {
        "slug": "customs",
        "group": "Symbols",
        "skin": false
    },
    "🛄": {
        "slug": "baggage_claim",
        "group": "Symbols",
        "skin": false
    },
    "🛅": {
        "slug": "left_luggage",
        "group": "Symbols",
        "skin": false
    },
    "⚠️": {
        "slug": "warning",
        "group": "Symbols",
        "skin": false
    },
    "🚸": {
        "slug": "children_crossing",
        "group": "Symbols",
        "skin": false
    },
    "⛔": {
        "slug": "no_entry",
        "group": "Symbols",
        "skin": false
    },
    "🚫": {
        "slug": "prohibited",
        "group": "Symbols",
        "skin": false
    },
    "🚳": {
        "slug": "no_bicycles",
        "group": "Symbols",
        "skin": false
    },
    "🚭": {
        "slug": "no_smoking",
        "group": "Symbols",
        "skin": false
    },
    "🚯": {
        "slug": "no_littering",
        "group": "Symbols",
        "skin": false
    },
    "🚱": {
        "slug": "non_potable_water",
        "group": "Symbols",
        "skin": false
    },
    "🚷": {
        "slug": "no_pedestrians",
        "group": "Symbols",
        "skin": false
    },
    "📵": {
        "slug": "no_mobile_phones",
        "group": "Symbols",
        "skin": false
    },
    "🔞": {
        "slug": "no_one_under_eighteen",
        "group": "Symbols",
        "skin": false
    },
    "☢️": {
        "slug": "radioactive",
        "group": "Symbols",
        "skin": false
    },
    "☣️": {
        "slug": "biohazard",
        "group": "Symbols",
        "skin": false
    },
    "⬆️": {
        "slug": "up_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↗️": {
        "slug": "up_right_arrow",
        "group": "Symbols",
        "skin": false
    },
    "➡️": {
        "slug": "right_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↘️": {
        "slug": "down_right_arrow",
        "group": "Symbols",
        "skin": false
    },
    "⬇️": {
        "slug": "down_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↙️": {
        "slug": "down_left_arrow",
        "group": "Symbols",
        "skin": false
    },
    "⬅️": {
        "slug": "left_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↖️": {
        "slug": "up_left_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↕️": {
        "slug": "up_down_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↔️": {
        "slug": "left_right_arrow",
        "group": "Symbols",
        "skin": false
    },
    "↩️": {
        "slug": "right_arrow_curving_left",
        "group": "Symbols",
        "skin": false
    },
    "↪️": {
        "slug": "left_arrow_curving_right",
        "group": "Symbols",
        "skin": false
    },
    "⤴️": {
        "slug": "right_arrow_curving_up",
        "group": "Symbols",
        "skin": false
    },
    "⤵️": {
        "slug": "right_arrow_curving_down",
        "group": "Symbols",
        "skin": false
    },
    "🔃": {
        "slug": "clockwise_vertical_arrows",
        "group": "Symbols",
        "skin": false
    },
    "🔄": {
        "slug": "counterclockwise_arrows_button",
        "group": "Symbols",
        "skin": false
    },
    "🔙": {
        "slug": "back_arrow",
        "group": "Symbols",
        "skin": false
    },
    "🔚": {
        "slug": "end_arrow",
        "group": "Symbols",
        "skin": false
    },
    "🔛": {
        "slug": "on_arrow",
        "group": "Symbols",
        "skin": false
    },
    "🔜": {
        "slug": "soon_arrow",
        "group": "Symbols",
        "skin": false
    },
    "🔝": {
        "slug": "top_arrow",
        "group": "Symbols",
        "skin": false
    },
    "🛐": {
        "slug": "place_of_worship",
        "group": "Symbols",
        "skin": false
    },
    "⚛️": {
        "slug": "atom_symbol",
        "group": "Symbols",
        "skin": false
    },
    "🕉️": {
        "slug": "om",
        "group": "Symbols",
        "skin": false
    },
    "✡️": {
        "slug": "star_of_david",
        "group": "Symbols",
        "skin": false
    },
    "☸️": {
        "slug": "wheel_of_dharma",
        "group": "Symbols",
        "skin": false
    },
    "☯️": {
        "slug": "yin_yang",
        "group": "Symbols",
        "skin": false
    },
    "✝️": {
        "slug": "latin_cross",
        "group": "Symbols",
        "skin": false
    },
    "☦️": {
        "slug": "orthodox_cross",
        "group": "Symbols",
        "skin": false
    },
    "☪️": {
        "slug": "star_and_crescent",
        "group": "Symbols",
        "skin": false
    },
    "☮️": {
        "slug": "peace_symbol",
        "group": "Symbols",
        "skin": false
    },
    "🕎": {
        "slug": "menorah",
        "group": "Symbols",
        "skin": false
    },
    "🔯": {
        "slug": "dotted_six_pointed_star",
        "group": "Symbols",
        "skin": false
    },
    "🪯": {
        "slug": "khanda",
        "group": "Symbols",
        "skin": false
    },
    "♈": {
        "slug": "aries",
        "group": "Symbols",
        "skin": false
    },
    "♉": {
        "slug": "taurus",
        "group": "Symbols",
        "skin": false
    },
    "♊": {
        "slug": "gemini",
        "group": "Symbols",
        "skin": false
    },
    "♋": {
        "slug": "cancer",
        "group": "Symbols",
        "skin": false
    },
    "♌": {
        "slug": "leo",
        "group": "Symbols",
        "skin": false
    },
    "♍": {
        "slug": "virgo",
        "group": "Symbols",
        "skin": false
    },
    "♎": {
        "slug": "libra",
        "group": "Symbols",
        "skin": false
    },
    "♏": {
        "slug": "scorpio",
        "group": "Symbols",
        "skin": false
    },
    "♐": {
        "slug": "sagittarius",
        "group": "Symbols",
        "skin": false
    },
    "♑": {
        "slug": "capricorn",
        "group": "Symbols",
        "skin": false
    },
    "♒": {
        "slug": "aquarius",
        "group": "Symbols",
        "skin": false
    },
    "♓": {
        "slug": "pisces",
        "group": "Symbols",
        "skin": false
    },
    "⛎": {
        "slug": "ophiuchus",
        "group": "Symbols",
        "skin": false
    },
    "🔀": {
        "slug": "shuffle_tracks_button",
        "group": "Symbols",
        "skin": false
    },
    "🔁": {
        "slug": "repeat_button",
        "group": "Symbols",
        "skin": false
    },
    "🔂": {
        "slug": "repeat_single_button",
        "group": "Symbols",
        "skin": false
    },
    "▶️": {
        "slug": "play_button",
        "group": "Symbols",
        "skin": false
    },
    "⏩": {
        "slug": "fast_forward_button",
        "group": "Symbols",
        "skin": false
    },
    "⏭️": {
        "slug": "next_track_button",
        "group": "Symbols",
        "skin": false
    },
    "⏯️": {
        "slug": "play_or_pause_button",
        "group": "Symbols",
        "skin": false
    },
    "◀️": {
        "slug": "reverse_button",
        "group": "Symbols",
        "skin": false
    },
    "⏪": {
        "slug": "fast_reverse_button",
        "group": "Symbols",
        "skin": false
    },
    "⏮️": {
        "slug": "last_track_button",
        "group": "Symbols",
        "skin": false
    },
    "🔼": {
        "slug": "upwards_button",
        "group": "Symbols",
        "skin": false
    },
    "⏫": {
        "slug": "fast_up_button",
        "group": "Symbols",
        "skin": false
    },
    "🔽": {
        "slug": "downwards_button",
        "group": "Symbols",
        "skin": false
    },
    "⏬": {
        "slug": "fast_down_button",
        "group": "Symbols",
        "skin": false
    },
    "⏸️": {
        "slug": "pause_button",
        "group": "Symbols",
        "skin": false
    },
    "⏹️": {
        "slug": "stop_button",
        "group": "Symbols",
        "skin": false
    },
    "⏺️": {
        "slug": "record_button",
        "group": "Symbols",
        "skin": false
    },
    "⏏️": {
        "slug": "eject_button",
        "group": "Symbols",
        "skin": false
    },
    "🎦": {
        "slug": "cinema",
        "group": "Symbols",
        "skin": false
    },
    "🔅": {
        "slug": "dim_button",
        "group": "Symbols",
        "skin": false
    },
    "🔆": {
        "slug": "bright_button",
        "group": "Symbols",
        "skin": false
    },
    "📶": {
        "slug": "antenna_bars",
        "group": "Symbols",
        "skin": false
    },
    "🛜": {
        "slug": "wireless",
        "group": "Symbols",
        "skin": false
    },
    "📳": {
        "slug": "vibration_mode",
        "group": "Symbols",
        "skin": false
    },
    "📴": {
        "slug": "mobile_phone_off",
        "group": "Symbols",
        "skin": false
    },
    "♀️": {
        "slug": "female_sign",
        "group": "Symbols",
        "skin": false
    },
    "♂️": {
        "slug": "male_sign",
        "group": "Symbols",
        "skin": false
    },
    "⚧️": {
        "slug": "transgender_symbol",
        "group": "Symbols",
        "skin": false
    },
    "✖️": {
        "slug": "multiply",
        "group": "Symbols",
        "skin": false
    },
    "➕": {
        "slug": "plus",
        "group": "Symbols",
        "skin": false
    },
    "➖": {
        "slug": "minus",
        "group": "Symbols",
        "skin": false
    },
    "➗": {
        "slug": "divide",
        "group": "Symbols",
        "skin": false
    },
    "🟰": {
        "slug": "heavy_equals_sign",
        "group": "Symbols",
        "skin": false
    },
    "♾️": {
        "slug": "infinity",
        "group": "Symbols",
        "skin": false
    },
    "‼️": {
        "slug": "double_exclamation_mark",
        "group": "Symbols",
        "skin": false
    },
    "⁉️": {
        "slug": "exclamation_question_mark",
        "group": "Symbols",
        "skin": false
    },
    "❓": {
        "slug": "red_question_mark",
        "group": "Symbols",
        "skin": false
    },
    "❔": {
        "slug": "white_question_mark",
        "group": "Symbols",
        "skin": false
    },
    "❕": {
        "slug": "white_exclamation_mark",
        "group": "Symbols",
        "skin": false
    },
    "❗": {
        "slug": "red_exclamation_mark",
        "group": "Symbols",
        "skin": false
    },
    "〰️": {
        "slug": "wavy_dash",
        "group": "Symbols",
        "skin": false
    },
    "💱": {
        "slug": "currency_exchange",
        "group": "Symbols",
        "skin": false
    },
    "💲": {
        "slug": "heavy_dollar_sign",
        "group": "Symbols",
        "skin": false
    },
    "⚕️": {
        "slug": "medical_symbol",
        "group": "Symbols",
        "skin": false
    },
    "♻️": {
        "slug": "recycling_symbol",
        "group": "Symbols",
        "skin": false
    },
    "⚜️": {
        "slug": "fleur_de_lis",
        "group": "Symbols",
        "skin": false
    },
    "🔱": {
        "slug": "trident_emblem",
        "group": "Symbols",
        "skin": false
    },
    "📛": {
        "slug": "name_badge",
        "group": "Symbols",
        "skin": false
    },
    "🔰": {
        "slug": "japanese_symbol_for_beginner",
        "group": "Symbols",
        "skin": false
    },
    "⭕": {
        "slug": "hollow_red_circle",
        "group": "Symbols",
        "skin": false
    },
    "✅": {
        "slug": "check_mark_button",
        "group": "Symbols",
        "skin": false
    },
    "☑️": {
        "slug": "check_box_with_check",
        "group": "Symbols",
        "skin": false
    },
    "✔️": {
        "slug": "check_mark",
        "group": "Symbols",
        "skin": false
    },
    "❌": {
        "slug": "cross_mark",
        "group": "Symbols",
        "skin": false
    },
    "❎": {
        "slug": "cross_mark_button",
        "group": "Symbols",
        "skin": false
    },
    "➰": {
        "slug": "curly_loop",
        "group": "Symbols",
        "skin": false
    },
    "➿": {
        "slug": "double_curly_loop",
        "group": "Symbols",
        "skin": false
    },
    "〽️": {
        "slug": "part_alternation_mark",
        "group": "Symbols",
        "skin": false
    },
    "✳️": {
        "slug": "eight_spoked_asterisk",
        "group": "Symbols",
        "skin": false
    },
    "✴️": {
        "slug": "eight_pointed_star",
        "group": "Symbols",
        "skin": false
    },
    "❇️": {
        "slug": "sparkle",
        "group": "Symbols",
        "skin": false
    },
    "©️": {
        "slug": "copyright",
        "group": "Symbols",
        "skin": false
    },
    "®️": {
        "slug": "registered",
        "group": "Symbols",
        "skin": false
    },
    "™️": {
        "slug": "trade_mark",
        "group": "Symbols",
        "skin": false
    },
    "#️⃣": {
        "slug": "keycap_number_sign",
        "group": "Symbols",
        "skin": false
    },
    "*️⃣": {
        "slug": "keycap_asterisk",
        "group": "Symbols",
        "skin": false
    },
    "0️⃣": {
        "slug": "keycap_0",
        "group": "Symbols",
        "skin": false
    },
    "1️⃣": {
        "slug": "keycap_1",
        "group": "Symbols",
        "skin": false
    },
    "2️⃣": {
        "slug": "keycap_2",
        "group": "Symbols",
        "skin": false
    },
    "3️⃣": {
        "slug": "keycap_3",
        "group": "Symbols",
        "skin": false
    },
    "4️⃣": {
        "slug": "keycap_4",
        "group": "Symbols",
        "skin": false
    },
    "5️⃣": {
        "slug": "keycap_5",
        "group": "Symbols",
        "skin": false
    },
    "6️⃣": {
        "slug": "keycap_6",
        "group": "Symbols",
        "skin": false
    },
    "7️⃣": {
        "slug": "keycap_7",
        "group": "Symbols",
        "skin": false
    },
    "8️⃣": {
        "slug": "keycap_8",
        "group": "Symbols",
        "skin": false
    },
    "9️⃣": {
        "slug": "keycap_9",
        "group": "Symbols",
        "skin": false
    },
    "🔟": {
        "slug": "keycap_10",
        "group": "Symbols",
        "skin": false
    },
    "🔠": {
        "slug": "input_latin_uppercase",
        "group": "Symbols",
        "skin": false
    },
    "🔡": {
        "slug": "input_latin_lowercase",
        "group": "Symbols",
        "skin": false
    },
    "🔢": {
        "slug": "input_numbers",
        "group": "Symbols",
        "skin": false
    },
    "🔣": {
        "slug": "input_symbols",
        "group": "Symbols",
        "skin": false
    },
    "🔤": {
        "slug": "input_latin_letters",
        "group": "Symbols",
        "skin": false
    },
    "🅰️": {
        "slug": "a_button",
        "group": "Symbols",
        "skin": false
    },
    "🆎": {
        "slug": "ab_button",
        "group": "Symbols",
        "skin": false
    },
    "🅱️": {
        "slug": "b_button",
        "group": "Symbols",
        "skin": false
    },
    "🆑": {
        "slug": "cl_button",
        "group": "Symbols",
        "skin": false
    },
    "🆒": {
        "slug": "cool_button",
        "group": "Symbols",
        "skin": false
    },
    "🆓": {
        "slug": "free_button",
        "group": "Symbols",
        "skin": false
    },
    "ℹ️": {
        "slug": "information",
        "group": "Symbols",
        "skin": false
    },
    "🆔": {
        "slug": "id_button",
        "group": "Symbols",
        "skin": false
    },
    "Ⓜ️": {
        "slug": "circled_m",
        "group": "Symbols",
        "skin": false
    },
    "🆕": {
        "slug": "new_button",
        "group": "Symbols",
        "skin": false
    },
    "🆖": {
        "slug": "ng_button",
        "group": "Symbols",
        "skin": false
    },
    "🅾️": {
        "slug": "o_button",
        "group": "Symbols",
        "skin": false
    },
    "🆗": {
        "slug": "ok_button",
        "group": "Symbols",
        "skin": false
    },
    "🅿️": {
        "slug": "p_button",
        "group": "Symbols",
        "skin": false
    },
    "🆘": {
        "slug": "sos_button",
        "group": "Symbols",
        "skin": false
    },
    "🆙": {
        "slug": "up_button",
        "group": "Symbols",
        "skin": false
    },
    "🆚": {
        "slug": "vs_button",
        "group": "Symbols",
        "skin": false
    },
    "🈁": {
        "slug": "japanese_here_button",
        "group": "Symbols",
        "skin": false
    },
    "🈂️": {
        "slug": "japanese_service_charge_button",
        "group": "Symbols",
        "skin": false
    },
    "🈷️": {
        "slug": "japanese_monthly_amount_button",
        "group": "Symbols",
        "skin": false
    },
    "🈶": {
        "slug": "japanese_not_free_of_charge_button",
        "group": "Symbols",
        "skin": false
    },
    "🈯": {
        "slug": "japanese_reserved_button",
        "group": "Symbols",
        "skin": false
    },
    "🉐": {
        "slug": "japanese_bargain_button",
        "group": "Symbols",
        "skin": false
    },
    "🈹": {
        "slug": "japanese_discount_button",
        "group": "Symbols",
        "skin": false
    },
    "🈚": {
        "slug": "japanese_free_of_charge_button",
        "group": "Symbols",
        "skin": false
    },
    "🈲": {
        "slug": "japanese_prohibited_button",
        "group": "Symbols",
        "skin": false
    },
    "🉑": {
        "slug": "japanese_acceptable_button",
        "group": "Symbols",
        "skin": false
    },
    "🈸": {
        "slug": "japanese_application_button",
        "group": "Symbols",
        "skin": false
    },
    "🈴": {
        "slug": "japanese_passing_grade_button",
        "group": "Symbols",
        "skin": false
    },
    "🈳": {
        "slug": "japanese_vacancy_button",
        "group": "Symbols",
        "skin": false
    },
    "㊗️": {
        "slug": "japanese_congratulations_button",
        "group": "Symbols",
        "skin": false
    },
    "㊙️": {
        "slug": "japanese_secret_button",
        "group": "Symbols",
        "skin": false
    },
    "🈺": {
        "slug": "japanese_open_for_business_button",
        "group": "Symbols",
        "skin": false
    },
    "🈵": {
        "slug": "japanese_no_vacancy_button",
        "group": "Symbols",
        "skin": false
    },
    "🔴": {
        "slug": "red_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟠": {
        "slug": "orange_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟡": {
        "slug": "yellow_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟢": {
        "slug": "green_circle",
        "group": "Symbols",
        "skin": false
    },
    "🔵": {
        "slug": "blue_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟣": {
        "slug": "purple_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟤": {
        "slug": "brown_circle",
        "group": "Symbols",
        "skin": false
    },
    "⚫": {
        "slug": "black_circle",
        "group": "Symbols",
        "skin": false
    },
    "⚪": {
        "slug": "white_circle",
        "group": "Symbols",
        "skin": false
    },
    "🟥": {
        "slug": "red_square",
        "group": "Symbols",
        "skin": false
    },
    "🟧": {
        "slug": "orange_square",
        "group": "Symbols",
        "skin": false
    },
    "🟨": {
        "slug": "yellow_square",
        "group": "Symbols",
        "skin": false
    },
    "🟩": {
        "slug": "green_square",
        "group": "Symbols",
        "skin": false
    },
    "🟦": {
        "slug": "blue_square",
        "group": "Symbols",
        "skin": false
    },
    "🟪": {
        "slug": "purple_square",
        "group": "Symbols",
        "skin": false
    },
    "🟫": {
        "slug": "brown_square",
        "group": "Symbols",
        "skin": false
    },
    "⬛": {
        "slug": "black_large_square",
        "group": "Symbols",
        "skin": false
    },
    "⬜": {
        "slug": "white_large_square",
        "group": "Symbols",
        "skin": false
    },
    "◼️": {
        "slug": "black_medium_square",
        "group": "Symbols",
        "skin": false
    },
    "◻️": {
        "slug": "white_medium_square",
        "group": "Symbols",
        "skin": false
    },
    "◾": {
        "slug": "black_medium_small_square",
        "group": "Symbols",
        "skin": false
    },
    "◽": {
        "slug": "white_medium_small_square",
        "group": "Symbols",
        "skin": false
    },
    "▪️": {
        "slug": "black_small_square",
        "group": "Symbols",
        "skin": false
    },
    "▫️": {
        "slug": "white_small_square",
        "group": "Symbols",
        "skin": false
    },
    "🔶": {
        "slug": "large_orange_diamond",
        "group": "Symbols",
        "skin": false
    },
    "🔷": {
        "slug": "large_blue_diamond",
        "group": "Symbols",
        "skin": false
    },
    "🔸": {
        "slug": "small_orange_diamond",
        "group": "Symbols",
        "skin": false
    },
    "🔹": {
        "slug": "small_blue_diamond",
        "group": "Symbols",
        "skin": false
    },
    "🔺": {
        "slug": "red_triangle_pointed_up",
        "group": "Symbols",
        "skin": false
    },
    "🔻": {
        "slug": "red_triangle_pointed_down",
        "group": "Symbols",
        "skin": false
    },
    "💠": {
        "slug": "diamond_with_a_dot",
        "group": "Symbols",
        "skin": false
    },
    "🔘": {
        "slug": "radio_button",
        "group": "Symbols",
        "skin": false
    },
    "🔳": {
        "slug": "white_square_button",
        "group": "Symbols",
        "skin": false
    },
    "🔲": {
        "slug": "black_square_button",
        "group": "Symbols",
        "skin": false
    },
    "🏁": {
        "slug": "chequered_flag",
        "group": "Flags",
        "skin": false
    },
    "🚩": {
        "slug": "triangular_flag",
        "group": "Flags",
        "skin": false
    },
    "🎌": {
        "slug": "crossed_flags",
        "group": "Flags",
        "skin": false
    },
    "🏴": {
        "slug": "black_flag",
        "group": "Flags",
        "skin": false
    },
    "🏳️": {
        "slug": "white_flag",
        "group": "Flags",
        "skin": false
    },
    "🏳️‍🌈": {
        "slug": "rainbow_flag",
        "group": "Flags",
        "skin": false
    },
    "🏳️‍⚧️": {
        "slug": "transgender_flag",
        "group": "Flags",
        "skin": false
    },
    "🏴‍☠️": {
        "slug": "pirate_flag",
        "group": "Flags",
        "skin": false
    },
    "🇦🇨": {
        "slug": "flag_ascension_island",
        "group": "Flags",
        "skin": false
    },
    "🇦🇩": {
        "slug": "flag_andorra",
        "group": "Flags",
        "skin": false
    },
    "🇦🇪": {
        "slug": "flag_united_arab_emirates",
        "group": "Flags",
        "skin": false
    },
    "🇦🇫": {
        "slug": "flag_afghanistan",
        "group": "Flags",
        "skin": false
    },
    "🇦🇬": {
        "slug": "flag_antigua_barbuda",
        "group": "Flags",
        "skin": false
    },
    "🇦🇮": {
        "slug": "flag_anguilla",
        "group": "Flags",
        "skin": false
    },
    "🇦🇱": {
        "slug": "flag_albania",
        "group": "Flags",
        "skin": false
    },
    "🇦🇲": {
        "slug": "flag_armenia",
        "group": "Flags",
        "skin": false
    },
    "🇦🇴": {
        "slug": "flag_angola",
        "group": "Flags",
        "skin": false
    },
    "🇦🇶": {
        "slug": "flag_antarctica",
        "group": "Flags",
        "skin": false
    },
    "🇦🇷": {
        "slug": "flag_argentina",
        "group": "Flags",
        "skin": false
    },
    "🇦🇸": {
        "slug": "flag_american_samoa",
        "group": "Flags",
        "skin": false
    },
    "🇦🇹": {
        "slug": "flag_austria",
        "group": "Flags",
        "skin": false
    },
    "🇦🇺": {
        "slug": "flag_australia",
        "group": "Flags",
        "skin": false
    },
    "🇦🇼": {
        "slug": "flag_aruba",
        "group": "Flags",
        "skin": false
    },
    "🇦🇽": {
        "slug": "flag_aland_islands",
        "group": "Flags",
        "skin": false
    },
    "🇦🇿": {
        "slug": "flag_azerbaijan",
        "group": "Flags",
        "skin": false
    },
    "🇧🇦": {
        "slug": "flag_bosnia_herzegovina",
        "group": "Flags",
        "skin": false
    },
    "🇧🇧": {
        "slug": "flag_barbados",
        "group": "Flags",
        "skin": false
    },
    "🇧🇩": {
        "slug": "flag_bangladesh",
        "group": "Flags",
        "skin": false
    },
    "🇧🇪": {
        "slug": "flag_belgium",
        "group": "Flags",
        "skin": false
    },
    "🇧🇫": {
        "slug": "flag_burkina_faso",
        "group": "Flags",
        "skin": false
    },
    "🇧🇬": {
        "slug": "flag_bulgaria",
        "group": "Flags",
        "skin": false
    },
    "🇧🇭": {
        "slug": "flag_bahrain",
        "group": "Flags",
        "skin": false
    },
    "🇧🇮": {
        "slug": "flag_burundi",
        "group": "Flags",
        "skin": false
    },
    "🇧🇯": {
        "slug": "flag_benin",
        "group": "Flags",
        "skin": false
    },
    "🇧🇱": {
        "slug": "flag_st_barthelemy",
        "group": "Flags",
        "skin": false
    },
    "🇧🇲": {
        "slug": "flag_bermuda",
        "group": "Flags",
        "skin": false
    },
    "🇧🇳": {
        "slug": "flag_brunei",
        "group": "Flags",
        "skin": false
    },
    "🇧🇴": {
        "slug": "flag_bolivia",
        "group": "Flags",
        "skin": false
    },
    "🇧🇶": {
        "slug": "flag_caribbean_netherlands",
        "group": "Flags",
        "skin": false
    },
    "🇧🇷": {
        "slug": "flag_brazil",
        "group": "Flags",
        "skin": false
    },
    "🇧🇸": {
        "slug": "flag_bahamas",
        "group": "Flags",
        "skin": false
    },
    "🇧🇹": {
        "slug": "flag_bhutan",
        "group": "Flags",
        "skin": false
    },
    "🇧🇻": {
        "slug": "flag_bouvet_island",
        "group": "Flags",
        "skin": false
    },
    "🇧🇼": {
        "slug": "flag_botswana",
        "group": "Flags",
        "skin": false
    },
    "🇧🇾": {
        "slug": "flag_belarus",
        "group": "Flags",
        "skin": false
    },
    "🇧🇿": {
        "slug": "flag_belize",
        "group": "Flags",
        "skin": false
    },
    "🇨🇦": {
        "slug": "flag_canada",
        "group": "Flags",
        "skin": false
    },
    "🇨🇨": {
        "slug": "flag_cocos_islands",
        "group": "Flags",
        "skin": false
    },
    "🇨🇩": {
        "slug": "flag_congo_kinshasa",
        "group": "Flags",
        "skin": false
    },
    "🇨🇫": {
        "slug": "flag_central_african_republic",
        "group": "Flags",
        "skin": false
    },
    "🇨🇬": {
        "slug": "flag_congo_brazzaville",
        "group": "Flags",
        "skin": false
    },
    "🇨🇭": {
        "slug": "flag_switzerland",
        "group": "Flags",
        "skin": false
    },
    "🇨🇮": {
        "slug": "flag_cote_d_ivoire",
        "group": "Flags",
        "skin": false
    },
    "🇨🇰": {
        "slug": "flag_cook_islands",
        "group": "Flags",
        "skin": false
    },
    "🇨🇱": {
        "slug": "flag_chile",
        "group": "Flags",
        "skin": false
    },
    "🇨🇲": {
        "slug": "flag_cameroon",
        "group": "Flags",
        "skin": false
    },
    "🇨🇳": {
        "slug": "flag_china",
        "group": "Flags",
        "skin": false
    },
    "🇨🇴": {
        "slug": "flag_colombia",
        "group": "Flags",
        "skin": false
    },
    "🇨🇵": {
        "slug": "flag_clipperton_island",
        "group": "Flags",
        "skin": false
    },
    "🇨🇷": {
        "slug": "flag_costa_rica",
        "group": "Flags",
        "skin": false
    },
    "🇨🇺": {
        "slug": "flag_cuba",
        "group": "Flags",
        "skin": false
    },
    "🇨🇻": {
        "slug": "flag_cape_verde",
        "group": "Flags",
        "skin": false
    },
    "🇨🇼": {
        "slug": "flag_curacao",
        "group": "Flags",
        "skin": false
    },
    "🇨🇽": {
        "slug": "flag_christmas_island",
        "group": "Flags",
        "skin": false
    },
    "🇨🇾": {
        "slug": "flag_cyprus",
        "group": "Flags",
        "skin": false
    },
    "🇨🇿": {
        "slug": "flag_czechia",
        "group": "Flags",
        "skin": false
    },
    "🇩🇪": {
        "slug": "flag_germany",
        "group": "Flags",
        "skin": false
    },
    "🇩🇬": {
        "slug": "flag_diego_garcia",
        "group": "Flags",
        "skin": false
    },
    "🇩🇯": {
        "slug": "flag_djibouti",
        "group": "Flags",
        "skin": false
    },
    "🇩🇰": {
        "slug": "flag_denmark",
        "group": "Flags",
        "skin": false
    },
    "🇩🇲": {
        "slug": "flag_dominica",
        "group": "Flags",
        "skin": false
    },
    "🇩🇴": {
        "slug": "flag_dominican_republic",
        "group": "Flags",
        "skin": false
    },
    "🇩🇿": {
        "slug": "flag_algeria",
        "group": "Flags",
        "skin": false
    },
    "🇪🇦": {
        "slug": "flag_ceuta_melilla",
        "group": "Flags",
        "skin": false
    },
    "🇪🇨": {
        "slug": "flag_ecuador",
        "group": "Flags",
        "skin": false
    },
    "🇪🇪": {
        "slug": "flag_estonia",
        "group": "Flags",
        "skin": false
    },
    "🇪🇬": {
        "slug": "flag_egypt",
        "group": "Flags",
        "skin": false
    },
    "🇪🇭": {
        "slug": "flag_western_sahara",
        "group": "Flags",
        "skin": false
    },
    "🇪🇷": {
        "slug": "flag_eritrea",
        "group": "Flags",
        "skin": false
    },
    "🇪🇸": {
        "slug": "flag_spain",
        "group": "Flags",
        "skin": false
    },
    "🇪🇹": {
        "slug": "flag_ethiopia",
        "group": "Flags",
        "skin": false
    },
    "🇪🇺": {
        "slug": "flag_european_union",
        "group": "Flags",
        "skin": false
    },
    "🇫🇮": {
        "slug": "flag_finland",
        "group": "Flags",
        "skin": false
    },
    "🇫🇯": {
        "slug": "flag_fiji",
        "group": "Flags",
        "skin": false
    },
    "🇫🇰": {
        "slug": "flag_falkland_islands",
        "group": "Flags",
        "skin": false
    },
    "🇫🇲": {
        "slug": "flag_micronesia",
        "group": "Flags",
        "skin": false
    },
    "🇫🇴": {
        "slug": "flag_faroe_islands",
        "group": "Flags",
        "skin": false
    },
    "🇫🇷": {
        "slug": "flag_france",
        "group": "Flags",
        "skin": false
    },
    "🇬🇦": {
        "slug": "flag_gabon",
        "group": "Flags",
        "skin": false
    },
    "🇬🇧": {
        "slug": "flag_united_kingdom",
        "group": "Flags",
        "skin": false
    },
    "🇬🇩": {
        "slug": "flag_grenada",
        "group": "Flags",
        "skin": false
    },
    "🇬🇪": {
        "slug": "flag_georgia",
        "group": "Flags",
        "skin": false
    },
    "🇬🇫": {
        "slug": "flag_french_guiana",
        "group": "Flags",
        "skin": false
    },
    "🇬🇬": {
        "slug": "flag_guernsey",
        "group": "Flags",
        "skin": false
    },
    "🇬🇭": {
        "slug": "flag_ghana",
        "group": "Flags",
        "skin": false
    },
    "🇬🇮": {
        "slug": "flag_gibraltar",
        "group": "Flags",
        "skin": false
    },
    "🇬🇱": {
        "slug": "flag_greenland",
        "group": "Flags",
        "skin": false
    },
    "🇬🇲": {
        "slug": "flag_gambia",
        "group": "Flags",
        "skin": false
    },
    "🇬🇳": {
        "slug": "flag_guinea",
        "group": "Flags",
        "skin": false
    },
    "🇬🇵": {
        "slug": "flag_guadeloupe",
        "group": "Flags",
        "skin": false
    },
    "🇬🇶": {
        "slug": "flag_equatorial_guinea",
        "group": "Flags",
        "skin": false
    },
    "🇬🇷": {
        "slug": "flag_greece",
        "group": "Flags",
        "skin": false
    },
    "🇬🇸": {
        "slug": "flag_south_georgia_south_sandwich_islands",
        "group": "Flags",
        "skin": false
    },
    "🇬🇹": {
        "slug": "flag_guatemala",
        "group": "Flags",
        "skin": false
    },
    "🇬🇺": {
        "slug": "flag_guam",
        "group": "Flags",
        "skin": false
    },
    "🇬🇼": {
        "slug": "flag_guinea_bissau",
        "group": "Flags",
        "skin": false
    },
    "🇬🇾": {
        "slug": "flag_guyana",
        "group": "Flags",
        "skin": false
    },
    "🇭🇰": {
        "slug": "flag_hong_kong_sar_china",
        "group": "Flags",
        "skin": false
    },
    "🇭🇲": {
        "slug": "flag_heard_mcdonald_islands",
        "group": "Flags",
        "skin": false
    },
    "🇭🇳": {
        "slug": "flag_honduras",
        "group": "Flags",
        "skin": false
    },
    "🇭🇷": {
        "slug": "flag_croatia",
        "group": "Flags",
        "skin": false
    },
    "🇭🇹": {
        "slug": "flag_haiti",
        "group": "Flags",
        "skin": false
    },
    "🇭🇺": {
        "slug": "flag_hungary",
        "group": "Flags",
        "skin": false
    },
    "🇮🇨": {
        "slug": "flag_canary_islands",
        "group": "Flags",
        "skin": false
    },
    "🇮🇩": {
        "slug": "flag_indonesia",
        "group": "Flags",
        "skin": false
    },
    "🇮🇪": {
        "slug": "flag_ireland",
        "group": "Flags",
        "skin": false
    },
    "🇮🇱": {
        "slug": "flag_israel",
        "group": "Flags",
        "skin": false
    },
    "🇮🇲": {
        "slug": "flag_isle_of_man",
        "group": "Flags",
        "skin": false
    },
    "🇮🇳": {
        "slug": "flag_india",
        "group": "Flags",
        "skin": false
    },
    "🇮🇴": {
        "slug": "flag_british_indian_ocean_territory",
        "group": "Flags",
        "skin": false
    },
    "🇮🇶": {
        "slug": "flag_iraq",
        "group": "Flags",
        "skin": false
    },
    "🇮🇷": {
        "slug": "flag_iran",
        "group": "Flags",
        "skin": false
    },
    "🇮🇸": {
        "slug": "flag_iceland",
        "group": "Flags",
        "skin": false
    },
    "🇮🇹": {
        "slug": "flag_italy",
        "group": "Flags",
        "skin": false
    },
    "🇯🇪": {
        "slug": "flag_jersey",
        "group": "Flags",
        "skin": false
    },
    "🇯🇲": {
        "slug": "flag_jamaica",
        "group": "Flags",
        "skin": false
    },
    "🇯🇴": {
        "slug": "flag_jordan",
        "group": "Flags",
        "skin": false
    },
    "🇯🇵": {
        "slug": "flag_japan",
        "group": "Flags",
        "skin": false
    },
    "🇰🇪": {
        "slug": "flag_kenya",
        "group": "Flags",
        "skin": false
    },
    "🇰🇬": {
        "slug": "flag_kyrgyzstan",
        "group": "Flags",
        "skin": false
    },
    "🇰🇭": {
        "slug": "flag_cambodia",
        "group": "Flags",
        "skin": false
    },
    "🇰🇮": {
        "slug": "flag_kiribati",
        "group": "Flags",
        "skin": false
    },
    "🇰🇲": {
        "slug": "flag_comoros",
        "group": "Flags",
        "skin": false
    },
    "🇰🇳": {
        "slug": "flag_st_kitts_nevis",
        "group": "Flags",
        "skin": false
    },
    "🇰🇵": {
        "slug": "flag_north_korea",
        "group": "Flags",
        "skin": false
    },
    "🇰🇷": {
        "slug": "flag_south_korea",
        "group": "Flags",
        "skin": false
    },
    "🇰🇼": {
        "slug": "flag_kuwait",
        "group": "Flags",
        "skin": false
    },
    "🇰🇾": {
        "slug": "flag_cayman_islands",
        "group": "Flags",
        "skin": false
    },
    "🇰🇿": {
        "slug": "flag_kazakhstan",
        "group": "Flags",
        "skin": false
    },
    "🇱🇦": {
        "slug": "flag_laos",
        "group": "Flags",
        "skin": false
    },
    "🇱🇧": {
        "slug": "flag_lebanon",
        "group": "Flags",
        "skin": false
    },
    "🇱🇨": {
        "slug": "flag_st_lucia",
        "group": "Flags",
        "skin": false
    },
    "🇱🇮": {
        "slug": "flag_liechtenstein",
        "group": "Flags",
        "skin": false
    },
    "🇱🇰": {
        "slug": "flag_sri_lanka",
        "group": "Flags",
        "skin": false
    },
    "🇱🇷": {
        "slug": "flag_liberia",
        "group": "Flags",
        "skin": false
    },
    "🇱🇸": {
        "slug": "flag_lesotho",
        "group": "Flags",
        "skin": false
    },
    "🇱🇹": {
        "slug": "flag_lithuania",
        "group": "Flags",
        "skin": false
    },
    "🇱🇺": {
        "slug": "flag_luxembourg",
        "group": "Flags",
        "skin": false
    },
    "🇱🇻": {
        "slug": "flag_latvia",
        "group": "Flags",
        "skin": false
    },
    "🇱🇾": {
        "slug": "flag_libya",
        "group": "Flags",
        "skin": false
    },
    "🇲🇦": {
        "slug": "flag_morocco",
        "group": "Flags",
        "skin": false
    },
    "🇲🇨": {
        "slug": "flag_monaco",
        "group": "Flags",
        "skin": false
    },
    "🇲🇩": {
        "slug": "flag_moldova",
        "group": "Flags",
        "skin": false
    },
    "🇲🇪": {
        "slug": "flag_montenegro",
        "group": "Flags",
        "skin": false
    },
    "🇲🇫": {
        "slug": "flag_st_martin",
        "group": "Flags",
        "skin": false
    },
    "🇲🇬": {
        "slug": "flag_madagascar",
        "group": "Flags",
        "skin": false
    },
    "🇲🇭": {
        "slug": "flag_marshall_islands",
        "group": "Flags",
        "skin": false
    },
    "🇲🇰": {
        "slug": "flag_north_macedonia",
        "group": "Flags",
        "skin": false
    },
    "🇲🇱": {
        "slug": "flag_mali",
        "group": "Flags",
        "skin": false
    },
    "🇲🇲": {
        "slug": "flag_myanmar",
        "group": "Flags",
        "skin": false
    },
    "🇲🇳": {
        "slug": "flag_mongolia",
        "group": "Flags",
        "skin": false
    },
    "🇲🇴": {
        "slug": "flag_macao_sar_china",
        "group": "Flags",
        "skin": false
    },
    "🇲🇵": {
        "slug": "flag_northern_mariana_islands",
        "group": "Flags",
        "skin": false
    },
    "🇲🇶": {
        "slug": "flag_martinique",
        "group": "Flags",
        "skin": false
    },
    "🇲🇷": {
        "slug": "flag_mauritania",
        "group": "Flags",
        "skin": false
    },
    "🇲🇸": {
        "slug": "flag_montserrat",
        "group": "Flags",
        "skin": false
    },
    "🇲🇹": {
        "slug": "flag_malta",
        "group": "Flags",
        "skin": false
    },
    "🇲🇺": {
        "slug": "flag_mauritius",
        "group": "Flags",
        "skin": false
    },
    "🇲🇻": {
        "slug": "flag_maldives",
        "group": "Flags",
        "skin": false
    },
    "🇲🇼": {
        "slug": "flag_malawi",
        "group": "Flags",
        "skin": false
    },
    "🇲🇽": {
        "slug": "flag_mexico",
        "group": "Flags",
        "skin": false
    },
    "🇲🇾": {
        "slug": "flag_malaysia",
        "group": "Flags",
        "skin": false
    },
    "🇲🇿": {
        "slug": "flag_mozambique",
        "group": "Flags",
        "skin": false
    },
    "🇳🇦": {
        "slug": "flag_namibia",
        "group": "Flags",
        "skin": false
    },
    "🇳🇨": {
        "slug": "flag_new_caledonia",
        "group": "Flags",
        "skin": false
    },
    "🇳🇪": {
        "slug": "flag_niger",
        "group": "Flags",
        "skin": false
    },
    "🇳🇫": {
        "slug": "flag_norfolk_island",
        "group": "Flags",
        "skin": false
    },
    "🇳🇬": {
        "slug": "flag_nigeria",
        "group": "Flags",
        "skin": false
    },
    "🇳🇮": {
        "slug": "flag_nicaragua",
        "group": "Flags",
        "skin": false
    },
    "🇳🇱": {
        "slug": "flag_netherlands",
        "group": "Flags",
        "skin": false
    },
    "🇳🇴": {
        "slug": "flag_norway",
        "group": "Flags",
        "skin": false
    },
    "🇳🇵": {
        "slug": "flag_nepal",
        "group": "Flags",
        "skin": false
    },
    "🇳🇷": {
        "slug": "flag_nauru",
        "group": "Flags",
        "skin": false
    },
    "🇳🇺": {
        "slug": "flag_niue",
        "group": "Flags",
        "skin": false
    },
    "🇳🇿": {
        "slug": "flag_new_zealand",
        "group": "Flags",
        "skin": false
    },
    "🇴🇲": {
        "slug": "flag_oman",
        "group": "Flags",
        "skin": false
    },
    "🇵🇦": {
        "slug": "flag_panama",
        "group": "Flags",
        "skin": false
    },
    "🇵🇪": {
        "slug": "flag_peru",
        "group": "Flags",
        "skin": false
    },
    "🇵🇫": {
        "slug": "flag_french_polynesia",
        "group": "Flags",
        "skin": false
    },
    "🇵🇬": {
        "slug": "flag_papua_new_guinea",
        "group": "Flags",
        "skin": false
    },
    "🇵🇭": {
        "slug": "flag_philippines",
        "group": "Flags",
        "skin": false
    },
    "🇵🇰": {
        "slug": "flag_pakistan",
        "group": "Flags",
        "skin": false
    },
    "🇵🇱": {
        "slug": "flag_poland",
        "group": "Flags",
        "skin": false
    },
    "🇵🇲": {
        "slug": "flag_st_pierre_miquelon",
        "group": "Flags",
        "skin": false
    },
    "🇵🇳": {
        "slug": "flag_pitcairn_islands",
        "group": "Flags",
        "skin": false
    },
    "🇵🇷": {
        "slug": "flag_puerto_rico",
        "group": "Flags",
        "skin": false
    },
    "🇵🇸": {
        "slug": "flag_palestinian_territories",
        "group": "Flags",
        "skin": false
    },
    "🇵🇹": {
        "slug": "flag_portugal",
        "group": "Flags",
        "skin": false
    },
    "🇵🇼": {
        "slug": "flag_palau",
        "group": "Flags",
        "skin": false
    },
    "🇵🇾": {
        "slug": "flag_paraguay",
        "group": "Flags",
        "skin": false
    },
    "🇶🇦": {
        "slug": "flag_qatar",
        "group": "Flags",
        "skin": false
    },
    "🇷🇪": {
        "slug": "flag_reunion",
        "group": "Flags",
        "skin": false
    },
    "🇷🇴": {
        "slug": "flag_romania",
        "group": "Flags",
        "skin": false
    },
    "🇷🇸": {
        "slug": "flag_serbia",
        "group": "Flags",
        "skin": false
    },
    "🇷🇺": {
        "slug": "flag_russia",
        "group": "Flags",
        "skin": false
    },
    "🇷🇼": {
        "slug": "flag_rwanda",
        "group": "Flags",
        "skin": false
    },
    "🇸🇦": {
        "slug": "flag_saudi_arabia",
        "group": "Flags",
        "skin": false
    },
    "🇸🇧": {
        "slug": "flag_solomon_islands",
        "group": "Flags",
        "skin": false
    },
    "🇸🇨": {
        "slug": "flag_seychelles",
        "group": "Flags",
        "skin": false
    },
    "🇸🇩": {
        "slug": "flag_sudan",
        "group": "Flags",
        "skin": false
    },
    "🇸🇪": {
        "slug": "flag_sweden",
        "group": "Flags",
        "skin": false
    },
    "🇸🇬": {
        "slug": "flag_singapore",
        "group": "Flags",
        "skin": false
    },
    "🇸🇭": {
        "slug": "flag_st_helena",
        "group": "Flags",
        "skin": false
    },
    "🇸🇮": {
        "slug": "flag_slovenia",
        "group": "Flags",
        "skin": false
    },
    "🇸🇯": {
        "slug": "flag_svalbard_jan_mayen",
        "group": "Flags",
        "skin": false
    },
    "🇸🇰": {
        "slug": "flag_slovakia",
        "group": "Flags",
        "skin": false
    },
    "🇸🇱": {
        "slug": "flag_sierra_leone",
        "group": "Flags",
        "skin": false
    },
    "🇸🇲": {
        "slug": "flag_san_marino",
        "group": "Flags",
        "skin": false
    },
    "🇸🇳": {
        "slug": "flag_senegal",
        "group": "Flags",
        "skin": false
    },
    "🇸🇴": {
        "slug": "flag_somalia",
        "group": "Flags",
        "skin": false
    },
    "🇸🇷": {
        "slug": "flag_suriname",
        "group": "Flags",
        "skin": false
    },
    "🇸🇸": {
        "slug": "flag_south_sudan",
        "group": "Flags",
        "skin": false
    },
    "🇸🇹": {
        "slug": "flag_sao_tome_principe",
        "group": "Flags",
        "skin": false
    },
    "🇸🇻": {
        "slug": "flag_el_salvador",
        "group": "Flags",
        "skin": false
    },
    "🇸🇽": {
        "slug": "flag_sint_maarten",
        "group": "Flags",
        "skin": false
    },
    "🇸🇾": {
        "slug": "flag_syria",
        "group": "Flags",
        "skin": false
    },
    "🇸🇿": {
        "slug": "flag_eswatini",
        "group": "Flags",
        "skin": false
    },
    "🇹🇦": {
        "slug": "flag_tristan_da_cunha",
        "group": "Flags",
        "skin": false
    },
    "🇹🇨": {
        "slug": "flag_turks_caicos_islands",
        "group": "Flags",
        "skin": false
    },
    "🇹🇩": {
        "slug": "flag_chad",
        "group": "Flags",
        "skin": false
    },
    "🇹🇫": {
        "slug": "flag_french_southern_territories",
        "group": "Flags",
        "skin": false
    },
    "🇹🇬": {
        "slug": "flag_togo",
        "group": "Flags",
        "skin": false
    },
    "🇹🇭": {
        "slug": "flag_thailand",
        "group": "Flags",
        "skin": false
    },
    "🇹🇯": {
        "slug": "flag_tajikistan",
        "group": "Flags",
        "skin": false
    },
    "🇹🇰": {
        "slug": "flag_tokelau",
        "group": "Flags",
        "skin": false
    },
    "🇹🇱": {
        "slug": "flag_timor_leste",
        "group": "Flags",
        "skin": false
    },
    "🇹🇲": {
        "slug": "flag_turkmenistan",
        "group": "Flags",
        "skin": false
    },
    "🇹🇳": {
        "slug": "flag_tunisia",
        "group": "Flags",
        "skin": false
    },
    "🇹🇴": {
        "slug": "flag_tonga",
        "group": "Flags",
        "skin": false
    },
    "🇹🇷": {
        "slug": "flag_turkey",
        "group": "Flags",
        "skin": false
    },
    "🇹🇹": {
        "slug": "flag_trinidad_tobago",
        "group": "Flags",
        "skin": false
    },
    "🇹🇻": {
        "slug": "flag_tuvalu",
        "group": "Flags",
        "skin": false
    },
    "🇹🇼": {
        "slug": "flag_taiwan",
        "group": "Flags",
        "skin": false
    },
    "🇹🇿": {
        "slug": "flag_tanzania",
        "group": "Flags",
        "skin": false
    },
    "🇺🇦": {
        "slug": "flag_ukraine",
        "group": "Flags",
        "skin": false
    },
    "🇺🇬": {
        "slug": "flag_uganda",
        "group": "Flags",
        "skin": false
    },
    "🇺🇲": {
        "slug": "flag_u_s_outlying_islands",
        "group": "Flags",
        "skin": false
    },
    "🇺🇳": {
        "slug": "flag_united_nations",
        "group": "Flags",
        "skin": false
    },
    "🇺🇸": {
        "slug": "flag_united_states",
        "group": "Flags",
        "skin": false
    },
    "🇺🇾": {
        "slug": "flag_uruguay",
        "group": "Flags",
        "skin": false
    },
    "🇺🇿": {
        "slug": "flag_uzbekistan",
        "group": "Flags",
        "skin": false
    },
    "🇻🇦": {
        "slug": "flag_vatican_city",
        "group": "Flags",
        "skin": false
    },
    "🇻🇨": {
        "slug": "flag_st_vincent_grenadines",
        "group": "Flags",
        "skin": false
    },
    "🇻🇪": {
        "slug": "flag_venezuela",
        "group": "Flags",
        "skin": false
    },
    "🇻🇬": {
        "slug": "flag_british_virgin_islands",
        "group": "Flags",
        "skin": false
    },
    "🇻🇮": {
        "slug": "flag_u_s_virgin_islands",
        "group": "Flags",
        "skin": false
    },
    "🇻🇳": {
        "slug": "flag_vietnam",
        "group": "Flags",
        "skin": false
    },
    "🇻🇺": {
        "slug": "flag_vanuatu",
        "group": "Flags",
        "skin": false
    },
    "🇼🇫": {
        "slug": "flag_wallis_futuna",
        "group": "Flags",
        "skin": false
    },
    "🇼🇸": {
        "slug": "flag_samoa",
        "group": "Flags",
        "skin": false
    },
    "🇽🇰": {
        "slug": "flag_kosovo",
        "group": "Flags",
        "skin": false
    },
    "🇾🇪": {
        "slug": "flag_yemen",
        "group": "Flags",
        "skin": false
    },
    "🇾🇹": {
        "slug": "flag_mayotte",
        "group": "Flags",
        "skin": false
    },
    "🇿🇦": {
        "slug": "flag_south_africa",
        "group": "Flags",
        "skin": false
    },
    "🇿🇲": {
        "slug": "flag_zambia",
        "group": "Flags",
        "skin": false
    },
    "🇿🇼": {
        "slug": "flag_zimbabwe",
        "group": "Flags",
        "skin": false
    },
    "🏴󠁧󠁢󠁥󠁮󠁧󠁿": {
        "slug": "flag_england",
        "group": "Flags",
        "skin": false
    },
    "🏴󠁧󠁢󠁳󠁣󠁴󠁿": {
        "slug": "flag_scotland",
        "group": "Flags",
        "skin": false
    },
    "🏴󠁧󠁢󠁷󠁬󠁳󠁿": {
        "slug": "flag_wales",
        "group": "Flags",
        "skin": false
    }
}
