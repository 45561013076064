import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';

@Component({
  selector: 'cb-tab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `,
})
export class CbTabComponent implements OnInit {
  @Input() public title = '';
  @Input() public active = false;

  constructor() {}

  ngOnInit(): void {}
}

@Component({
  selector: 'cb-tabs',
  templateUrl: './cb-tabs.component.html',
  styleUrls: ['./cb-tabs.component.scss'],
})
export class CbTabsComponent implements AfterContentInit {
  @ContentChildren(CbTabComponent) tabs!: QueryList<CbTabComponent>;

  constructor() {}

  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: CbTabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
