import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbPinInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-pin-input',
  templateUrl: './cb-pin-input.component.html',
  styleUrls: ['./cb-pin-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbPinInputComponent implements ControlValueAccessor {
  @Input() private codeLength = 6;
  @Input() public error = false;

  @Output() public pinInserted: EventEmitter<string> = new EventEmitter<string>();

  public code: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.resetCode();
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.code.join("");
  }

  private resetCode(): void {
    this.code = [];
    for (let i = 0; i < this.codeLength; i++) {
      this.code.push("");
    }
    this.onChangeCallback(this.value);
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.value) {
      if (v) {
        const split = v.split("");
        for (let i = 0; i < split.length; i++) {
          if (i < this.codeLength && !isNaN(parseInt(split[i])))
            this.code[i] = split[i];
          else {
            this.resetCode();
            break;
          }
        }
        this.onChangeCallback(this.value);
      }
    }
  }

  public writeValue(value: any) {
    if (value !== this.value) {
      this.value = value;
      this.onChangeCallback(this.value);
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public codeChange(event: KeyboardEvent, idx: number): void {
    if (!event.metaKey) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }

    if (event.key >= "0" && event.key <= "9") {
      this.code[idx] = event.key;
      this.onChangeCallback(this.value);

      this.checkCode();

      // Focus the next input
      this.focusInput(idx + 1);
    } else if (event.key == "Delete" || event.key == "Backspace") {
      this.code[idx] = "";
      this.onChangeCallback(this.value);

      // Focus the previous input
      this.focusInput(idx - 1);
    } else if (
      event.key == "ArrowRight" ||
      event.key == "Enter" ||
      event.key == "Tab"
    ) {
      // Focus the next input
      this.focusInput(idx + 1);
    } else if (event.key == "ArrowLeft") {
      // Focus the previous input
      this.focusInput(idx - 1);
    }
  }

  private checkCode(): void {
    if (this.code.join("").length == this.codeLength) {
      this.pinInserted.emit(this.value);
    }
  }

  private focusInput(idx: number): void {
    if (idx >= 0 && idx <= this.code.length - 1) {
      setTimeout(() => {
        document.getElementById("pin-input-" + idx)?.focus();
      }, 0);
    }
  }

  public handlePaste(event: any): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('Text');
    this.value = pastedData;
  }
}
