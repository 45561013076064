<div class="cb-input-wrapper cb-file-input-container">
  <label>{{label | translate}}</label>
  <div class="cb-file-input-picker">
    <ng-container *ngIf="multiple || value.length==0">
      <ng-container *ngIf="!filedrop; else FileDropTemplate">
        <div class="cb-input-container cb-inline-file-picker" [class.disabled]="disabled" (click)="openFilePicker()">
          <input [multiple]="multiple" type="file" #filepicker [attr.accept]="accept" (change)="filePicked($event)" />
          <p>{{"COMMON.BROWSE_FILES" | translate}}</p>
          <cb-icon-button type="primary" icon="folder-add"></cb-icon-button>
        </div>
      </ng-container>
      <ng-template #FileDropTemplate>
        <ngx-file-drop [multiple]="multiple" [disabled]="disabled" [accept]="accept" dropZoneClassName="cb-drop-zone"
          contentClassName="cb-drop-content" [dropZoneLabel]="'COMMON.DROP_FILES' | translate"
          (onFileDrop)="dropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="cb-file-upload-zone" (click)="openFileSelector()">
              <cb-icon name="upload"></cb-icon>
              <p>{{uploadLabel | translate}}</p>
              <span *ngIf="placeholder">{{placeholder | translate}}</span>
            </div>
            <!-- <cb-button type="primary" (click)="openFileSelector()">{{"COMMON.BROWSE_FILES" | translate}}</cb-button> -->
          </ng-template>
        </ngx-file-drop>
      </ng-template>
    </ng-container>
  </div>
  <div class="cb-file-input-list" *ngIf="value && value.length>0">
    <ng-container *ngFor="let f of value; let idx=index">
      <div class="cb-file">
        <div class="cb-file-title">
          <ng-container [ngSwitch]="fileTypePreview(f)">
            <ng-container *ngSwitchCase="'image'">
              <div class="cb-file-image-preview" [style.background-image]="'url('+(f.url ?? relativePaths[f.name])+')'">
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'video'">
              <a [href]="f.url ?? relativePaths[f.name]" target="_blank">
                <div class="cb-file-video-preview">
                  <cb-icon name="play"></cb-icon>
                  <video [src]="f.url ?? relativePaths[f.name]"></video>
                </div>
              </a>
            </ng-container>
          </ng-container>
          <p>{{f.name}}</p>
        </div>
        <div class="cb-file-buttons">
          <cb-icon-button type="danger" icon="trash" (click)="removeFile(idx)"></cb-icon-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>