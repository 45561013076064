import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cb-table-skeleton',
  templateUrl: './cb-table-skeleton.component.html',
  styleUrls: ['./cb-table-skeleton.component.scss', '../cb-skeleton.scss']
})
export class CbTableSkeletonComponent implements OnInit {
  @Input() public headerCells = 4;
  @Input() public bodyRows = 5;

  constructor() { }

  ngOnInit(): void {
  }

  public get iterableCells(): number[] {
    return Array(this.headerCells).fill(0);
  }

  public get iterableRows(): number[] {
    return Array(this.bodyRows).fill(0);
  }
}
