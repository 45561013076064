import { Component, Input, OnInit } from '@angular/core';
import { CbStandardFormColumn, PropertyType } from '../../cb-form/cb-form-interfaces';

@Component({
  selector: 'cb-text-formatter',
  templateUrl: './cb-text-formatter.component.html',
  styleUrls: ['./cb-text-formatter.component.scss']
})
export class CbTextFormatterComponent implements OnInit {

  @Input() public type: PropertyType = "text";
  @Input() public showAll: boolean = false;
  @Input() public value: any;

  constructor() { }

  ngOnInit(): void {
  }

  public get valuesList(): any[] {
    // Check if this.value is an array
    if (Array.isArray(this.value)) {
      return this.value;
    } else {
      return [this.value];
    }
  }

  public getMonth(value: number): string {
    const months: string[] = [
      "COMMON.JANUARY",
      "COMMON.FEBRUARY",
      "COMMON.MARCH",
      "COMMON.APRIL",
      "COMMON.MAY",
      "COMMON.JUNE",
      "COMMON.JULY",
      "COMMON.AUGUST",
      "COMMON.SEPTEMBER",
      "COMMON.OCTOBER",
      "COMMON.NOVEMBER",
      "COMMON.DECEMBER"
    ];

    return months[value];
  }

  public getFilename(url: string): string | undefined {
    return url.split('/').pop();
  }

}
