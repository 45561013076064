<div class="cb-text-format" [class.clamp]="!showAll">
  <ng-container *ngFor="let value of valuesList; let idx=index">
    <ng-container [ngSwitch]="type">

      <ng-container *ngSwitchCase="'boolean'">
        <div class="cb-text-boolean" [class.is-true]="value">
          <ng-container *ngIf="value; else BooleanFalse">
            <ion-icon name="checkmark-circle"></ion-icon>
          </ng-container>
          <ng-template #BooleanFalse>
            <ion-icon name="close-circle"></ion-icon>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'time'">
        <p>{{ value | secondsTime }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'file'">
        <a [href]="value" target="_blank">
          <div class="cb-file-icon">
            <ion-icon name="document"></ion-icon>
          </div>
          <p>{{getFilename(value)}}</p>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        <p>{{ value | smartDate:'dd/mm/yyyy' }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'email'">
        <p><a [href]="'mailto:'+value">{{value}}</a></p>
      </ng-container>

      <ng-container *ngSwitchCase="'color'">
        <div class="cb-color-preview">
          <div class="cb-color-circle" [style.background-color]="value"></div>
          <p>{{value}}</p>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'month'">
        <p>{{getMonth(value) | translate}}</p>
      </ng-container>

      <ng-container *ngSwitchCase="'tel'">
        <p><a [href]="'tel:'+value">{{value}}</a></p>
      </ng-container>

      <ng-container *ngSwitchCase="'datetime'">
        <p>{{ value | smartDate }}</p>
      </ng-container>

      <ng-container *ngSwitchCase="'image'">
        <a [href]="value" target="_blank">
          <div class="cb-image-preview" [style.background-image]="'url('+value+')'"></div>
          <p>{{getFilename(value)}}</p>
        </a>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <p>{{ value }}</p>
      </ng-container>

    </ng-container>
  </ng-container>
</div>