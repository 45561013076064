<div class="cb-tabs-wrapper">
  <div class="cb-tabs-container">
    <ng-container *ngFor="let tab of tabs">
      <div class="cb-tab" [class.active]="tab.active" (click)="selectTab(tab)">{{tab.title}}</div>
    </ng-container>
  </div>
  <div class="cb-tabs-content">
    <ng-content></ng-content>
  </div>
</div>
