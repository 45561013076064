import { CbButtonType } from '../cb-form-widgets/cb-button/cb-button.component';

export interface CbFormButton {
    show: boolean;
    label: string;
    type: CbButtonType;
    disabledOnError?: boolean;
    onClick?: (values: { [key: string]: any }) => void;
}

export interface CbFormRow {
    columns: (
        | CbStandardFormColumn
        | CbTextareaFormColumn
        | CbOptionsFormColumn
        | CbListFormColumn
        | CbFileFormColumn
        | CbSliderFormColumn
    )[];
    data?: any;
}

export type CbFormColumn =
    | CbStandardFormColumn
    | CbTextareaFormColumn
    | CbOptionsFormColumn
    | CbListFormColumn
    | CbFileFormColumn
    | CbSliderFormColumn;

interface CbBasicFormColumn {
    show?: boolean;
    field: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    validator?: (value: any, values: any) => boolean;
    validatorError?: string;
    defaultValue?: any;
    data?: any;
    required?: boolean;
}

export interface CbStandardFormColumn extends CbBasicFormColumn {
    type: PropertyType
}

export declare type PropertyType =
    'string'
    | 'enum'
    | 'number'
    | 'text'
    | 'date'
    | 'time'
    | 'password'
    | 'slider'
    | 'file'
    | 'list'
    | 'boolean'
    | 'textarea'
    | 'email'
    | 'color'
    | 'month'
    | 'url'
    | 'search'
    | 'tel'
    | 'datetime'
    | 'image'
    | 'id'
    | 'externalid'
    | 'code'
    | 'stringlist';

export interface CbTextareaFormColumn extends CbBasicFormColumn {
    type: 'textarea';
    rows?: number;
}

export interface CbFileFormColumn extends CbBasicFormColumn {
    type: 'file';
    multiple?: boolean;
}

export interface CbOptionsFormColumn extends CbBasicFormColumn {
    type: 'enum' | 'multienum';
    options?: {
        value: any;
        label: string;
    }[];
    dynamic?: {
        dependsOn: string[];
        optionsGenerator: (values: { [key: string]: any }, data?: any) => { value: string; label: string; }[];
    };
}

export interface CbListFormColumn extends CbBasicFormColumn {
    type: 'list';
    minRows?: number;
    maxRows?: number;
    listChildren: {
        headers: string[];
        fields: (
            | CbStandardFormColumn
            | CbTextareaFormColumn
            | CbOptionsFormColumn
            | CbListFormColumn
            | CbSliderFormColumn
        )[];
    };
}

export interface CbSliderFormColumn extends CbBasicFormColumn {
    type: 'slider';
    min?: number;
    max?: number;
    step?: number;
    stepLabels?: string[];
}

export interface CbStandardForm {
    title: string;
    width?: string;
    rows: CbFormRow[];
    defaultObj?: { [key: string]: any };
    conditionalDisable?: {
        [key: string]: (values: { [key: string]: any }) => boolean;
    };
    submitButton?: CbFormButton;
    deleteButton?: CbFormButton;
    otherButtons?: CbFormButton[];
}
