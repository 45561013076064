<div class="cb-icon-color-picker">
  <h6>{{"COMMON.COLOR" | translate}}</h6>
  <div class="cb-color-picker">
    <ng-container *ngFor="let colorItem of colorsList">
      <div class="cb-color" [style.background-color]="colorItem.color" (click)="color = colorItem.color">
        <ng-container *ngIf="color === colorItem.color">
          <cb-icon name="checkmark-circle" [style.color]="colorItem.contrast"></cb-icon>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <h6>{{"COMMON.ICON" | translate}}</h6>
  <div class="cb-icon-picker">
    <div class="cb-icon-picker-settings">
      <div class="cb-icon-picker-search">
        <input type="text" [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="search" />
        <cb-icon name="search"></cb-icon>
      </div>
    </div>

    <div class="cb-icon-picker-list">
      <p-virtualScroller [value]="emojiRows" scrollHeight="200px" [itemSize]="30" styleClass="test">
        <ng-template pTemplate="item" let-emojiRow>
          <div class="cb-icon-picker-row">
            <ng-container *ngFor="let emoji of emojiRow">
              <div class="cb-icon-picker-emoji" (click)="setEmoji(emoji)">
                <span>
                  {{ emoji }}
                </span>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </p-virtualScroller>
    </div>
  </div>
</div>
