<div class="cb-translate-input-wrapper">

  <div class="cb-translate-input-container from-language">
    <div class="cb-translate-input-header">
      <div class="cb-translate-input-language">
        {{fromLangExtended}}
      </div>
    </div>
    <div class="cb-translate-input-value">
      <ng-container *ngIf="fromText; else NoTranslationsTemplate">
        <p>{{fromText}}</p>
      </ng-container>
      <ng-template #NoTranslationsTemplate>
        <p class="cb-no-translations">{{"TRANSLATE.NOTRANSLATIONS" | translate}}</p>
      </ng-template>
    </div>
  </div>

  <div class="cb-translate-input-container to-language">
    <div class="cb-translate-input-header">
      <div class="cb-translate-input-language">
        {{toLangExtended}}
      </div>
      <button class="cb-translate-input-button" (click)="autoTranslate()">
        <ion-icon name="language"></ion-icon>
        <p>{{"TRANSLATE.AUTOTRANSLATE" | translate}}</p>
      </button>
    </div>
    <textarea [placeholder]="'TRANSLATE.INPUT_PLACEHOLDER' | translate" rows="1" autosize [(ngModel)]="value"
      (change)="change()" (blur)="blur()"></textarea>
  </div>
</div>
