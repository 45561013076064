import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxFileDropEntry } from 'ngx-file-drop';

export interface CbFileValue {
  file?: File;
  name: string;
  url?: string;
}

@Component({
  selector: 'cb-file-input',
  templateUrl: './cb-file-input.component.html',
  styleUrls: ['./cb-file-input.component.scss', '../cb-form-style.scss'],
})
export class CbFileInputComponent {
  @Input() public placeholder = '';
  @Input() public label = '';
  @Input() public multiple = false;
  @Input() public filedrop = false;
  @Input() public accept = '*';
  @Input() public disabled = false;

  @Input() public set files(filenames: (string | { name: string, url: string })[]) {
    // Check if filenames is an array of strings or an array of objects
    if (filenames.length > 0 && typeof filenames[0] === 'string') {
      this.value = <any>filenames.map((filename) => ({ name: filename }));
    } else {
      this.value = (<any>filenames).map((file: any) => ({ name: file.name, url: file.url }));
    }
  }

  @ViewChild('filepicker') private filepickerRef?: ElementRef;

  @Output('filePick') public onFilePick: EventEmitter<CbFileValue[]> =
    new EventEmitter<CbFileValue[]>();
  @Output('fileRemove') public onFileRemove: EventEmitter<CbFileValue[]> =
    new EventEmitter<CbFileValue[]>();

  public value: CbFileValue[] = [];

  public relativePaths: { [key: string]: SafeResourceUrl | string } = {};

  constructor(private domSanitizer: DomSanitizer) { }

  public get uploadLabel(): string {
    if (this.accept == 'image/*') {
      return "COMMON.UPLOAD_IMAGE";
    } else if (this.accept == 'video/*') {
      return "COMMON.UPLOAD_VIDEO";
    } else if (this.accept == 'audio/*') {
      return "COMMON.UPLOAD_AUDIO";
    } else {
      return "COMMON.UPLOAD_FILE";
    }
  }

  public filePicked(event: any): void {
    this.onFilePick.emit(event.target.files);
  }

  public fileTypePreview(file: CbFileValue): string {
    if (this.isImage(file)) {
      return 'image';
    } else if (this.isVideo(file)) {
      return 'video';
    } else {
      return 'file';
    }
  }

  public isVideo(file: CbFileValue): boolean {
    // Return true if file is a video
    const filename = file.name.toLowerCase();

    return (
      filename.endsWith('.mp4') ||
      filename.endsWith('.webm') ||
      filename.endsWith('.mov')
    );
  }

  public isImage(file: CbFileValue): boolean {
    // Return true if file is an image
    const filename = file.name.toLowerCase();

    return (
      filename.endsWith('.jpg') ||
      filename.endsWith('.jpeg') ||
      filename.endsWith('.png') ||
      filename.endsWith('.gif') ||
      filename.endsWith('.svg')
    );
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (this.multiple || files.length === 1) {
      const value: CbFileValue[] = [];
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;


          fileEntry.file((file: File) => {
            const newFileValue: CbFileValue = { file: file, name: file.name };

            const url = URL.createObjectURL(file);
            const relativePath = (this.isVideo(newFileValue)) ? this.domSanitizer.bypassSecurityTrustResourceUrl(url) : url;
            this.relativePaths[fileEntry.name] = relativePath;

            value.push(newFileValue);
          });

        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
      this.value = value;
      this.onFilePick.emit(this.value);
    }
  }

  public openFilePicker(): void {
    if (this.filepickerRef) this.filepickerRef.nativeElement.click();
  }

  public test(): void {
    console.log("TEST")
  }

  public removeFile(fileIdx: number): void {
    this.value.splice(fileIdx, 1);
    this.onFileRemove.emit(this.value);
  }
}
