<div class="cb-table-skeleton-container">
  <div class="cb-table-skeleton-header">
    <ng-container *ngFor="let cell of iterableCells">
      <div class="cb-table-skeleton-cell loading-background"></div>
    </ng-container>
  </div>
  <div class="cb-table-skeleton-body">
    <ng-container *ngFor="let row of iterableRows">
      <div class="cb-table-skeleton-cell loading-background"></div>
    </ng-container>
  </div>
</div>
