import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbLangSelectComponent),
  multi: true,
};

@Component({
  selector: 'cb-lang-select',
  templateUrl: './cb-lang-select.component.html',
  styleUrls: ['./cb-lang-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbLangSelectComponent implements ControlValueAccessor {
  @Input('availableLangs') public availableLanguages: string[] = [];
  @Input() public autofocus = false;

  @Output() public change: EventEmitter<string> =
    new EventEmitter<string>();

  public readonly languagesOptions: { label: string; value: string }[] = [
    { label: 'Italiano', value: 'it' },
    { label: 'Español', value: 'es' },
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
  ];

  private innerValue!: string;

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public get options(): { label: string; value: string }[] {
    return this.languagesOptions.filter((option) =>
      this.availableLanguages.includes(option.value)
    );
  }

  // get accessor
  public get value(): string {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.cdr.detectChanges();
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.cdr.detectChanges();
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
