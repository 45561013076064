import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbMonthInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-month-input',
  templateUrl: './cb-month-input.component.html',
  styleUrls: ['./cb-month-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbMonthInputComponent implements ControlValueAccessor, OnInit {
  @Input() public label: string = '';
  @Input() public placeholder: string = '';
  @Input() public disabled: boolean = false;
  @Input() public error: boolean = false;

  public months = [
    { value: 0, label: "COMMON.JANUARY" },
    { value: 1, label: "COMMON.FEBRUARY" },
    { value: 2, label: "COMMON.MARCH" },
    { value: 3, label: "COMMON.APRIL" },
    { value: 4, label: "COMMON.MAY" },
    { value: 5, label: "COMMON.JUNE" },
    { value: 6, label: "COMMON.JULY" },
    { value: 7, label: "COMMON.AUGUST" },
    { value: 8, label: "COMMON.SEPTEMBER" },
    { value: 9, label: "COMMON.OCTOBER" },
    { value: 10, label: "COMMON.NOVEMBER" },
    { value: 11, label: "COMMON.DECEMBER" },
  ];

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {
    for (let i = 0; i < this.months.length; i++) {
      this.months[i].label = this.translate.instant(this.months[i].label);
    }
  }

  @Output('input') public onInput: EventEmitter<number> = new EventEmitter();
  @Output('change') public onChange: EventEmitter<number> = new EventEmitter();

  //The internal data model
  private innerValue: number = 0;

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): number {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: number) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public input(): void {
    this.onInput.emit(this.value);
  }
  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
