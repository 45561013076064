import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbPasswordComponent),
  multi: true,
};

@Component({
  selector: 'cb-password',
  templateUrl: './cb-password.component.html',
  styleUrls: ['./cb-password.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbPasswordComponent implements ControlValueAccessor, OnInit {
  @Input() public forgotPasswordPath: string = '';
  @Input() public label: string = '';
  @Input() public placeholder: string = '';
  @Input() public disabled: boolean = false;
  @Input() public error: boolean = false;

  @Input() public newPassword: boolean = false;

  @Output('input') public onInput: EventEmitter<any> = new EventEmitter();
  @Output('change') public onChange: EventEmitter<any> = new EventEmitter();

  //The internal data model
  private innerValue = '';

  constructor() { }

  ngOnInit(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public input(): void {
    this.onInput.emit(this.value);
  }
  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
