import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  public transform(value: number): any {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (value >= 1024) {
      value /= 1024;
      i++;
    }
    return value.toFixed(1) + ' ' + units[i];
  }
}
